import { createAction, createSlice } from "@reduxjs/toolkit";
import paymentService from "../service/payment.service";
import { checkGroupPayments } from "../utils/checkPayments";

const paymentsSlice = createSlice({
  name: "payment",
  initialState: {
    entities: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    paymentsRequested: (state) => {
      state.isLoading = true;
    },
    paymentsReceived: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
    },
    paymentCreateReceived: (state, action) => {
      state.entities.push(action.payload);
      state.isLoading = false;
    },
    paymentsRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    paymentRemoved: (state, action) => {
      state.entities = state.entities.filter((c) => c._id !== action.payload);
    },
    paymentLogOut: (state) => {
      state.entities = null;
    },
  },
});

const { reducer: paymentsReducer, actions } = paymentsSlice;

const {
  paymentsRequested,
  paymentsReceived,
  paymentsRequestFailed,
  paymentCreateReceived,
  paymentLogOut,
} = actions;

const addPaymentRequested = createAction("payment/addPaymentRequested");

export const loadPaymentList = () => async (dispatch) => {
  dispatch(paymentsRequested());
  try {
    const { content } = await paymentService.getPayments();
    if (content) {
      dispatch(paymentsReceived(content));
    }
  } catch (error) {
    dispatch(paymentsRequestFailed(error.message));
  }
};

export const createPayment = (payload) => async (dispatch) => {
  dispatch(addPaymentRequested(payload));
  try {
    const { content } = await paymentService.create(payload);
    dispatch(paymentCreateReceived(content));
  } catch (error) {
    dispatch(paymentsRequestFailed(error.message));
  }
};

export const getPayments = () => (state) => state.payment.entities;

export const getPaymentSumByMissionId = (missionId) => (state) => {
  if (state.payment.entities) {
    const payments = state.payment.entities.filter(
      (elem) => elem.missionId === missionId
    );
    const sum = checkGroupPayments(payments, missionId);

    return sum !== undefined ? sum : 0;
  }
};

export const logOutPayment = () => (dispatch) => {
  dispatch(paymentLogOut());
};

export default paymentsReducer;
