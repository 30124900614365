import React, { useState } from "react";
import { useParams } from "react-router-dom";
import LoginForm from "../ui/LoginForm";
import RegisterForm from "../ui/RegisterForm";

const Login = () => {
  const { type } = useParams();
  const [formType, setFormType] = useState(
    type === "register" ? type : "login"
  );
  const toggleFormType = (params) => {
    setFormType((prevState) =>
      prevState === "register" ? "login" : "register"
    );
  };
  return (
    <div className="container mt-5 h-screen">
      <div className="flex justify-center">
        <div className="flex-col col-md-6 offset-md-3 shadow p-4 max-w-md bg-slate-100">
          {formType === "register" ? (
            <>
              <h3 className="flex justify-center mb-4 py-5 font-serif font-bold text-lg camelCase">
                регистрация
              </h3>
              <RegisterForm />
              <div className="px-5">
                Already have account?
                <button
                  className="px-2 hover:text-emerald-400"
                  onClick={toggleFormType}
                >
                  Sign In
                </button>
              </div>
            </>
          ) : (
            <>
              <h3 className="flex justify-center mb-4 font-serif font-bold text-lg">
                вход
              </h3>
              <LoginForm />
              <div className="px-5">
                Dont have account?
                <button
                  className="px-2 hover:text-emerald-400"
                  onClick={toggleFormType}
                >
                  Sign Up
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
