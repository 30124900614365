import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsLoggedIn,
  getUsersLoadingStatus,
  loadUsersList,
} from "../../store/users";
import { loadSpecialtiesList } from "../../store/specialties";
import { loadGroupsList } from "../../store/groups";
import { loadLevelsList } from "../../store/levels";
import { loadDeletesList } from "../../store/delete";
import { loadInvitationList } from "../../store/invitations";
import { loadPaymentList } from "../../store/payment";
import { loadCountriesList } from "../../store/countries";
import { loadTownsList } from "../../store/towns";
import { loadSkillsList } from "../../store/skills";

const AppLoader = ({ children }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn());
  const usersStatusLoading = useSelector(getUsersLoadingStatus());

  useEffect(() => {
    dispatch(loadSpecialtiesList());
    dispatch(loadLevelsList());
    dispatch(loadSkillsList());
    dispatch(loadCountriesList());
    dispatch(loadTownsList());
    if (isLoggedIn) {
      dispatch(loadUsersList());
      dispatch(loadGroupsList());
      dispatch(loadDeletesList());
      dispatch(loadPaymentList());
      dispatch(loadInvitationList());
      dispatch(loadDeletesList());
    }
  }, [isLoggedIn]);

  if (usersStatusLoading)
    return (
      <div className="w-full h-screen">
        <p className="flex justify-center">Loading...</p>
      </div>
    );
  return children;
};

AppLoader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AppLoader;
