import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getGroupById } from "../store/groups";
import { getCurrentUserData } from "../store/users";

function MissionLink({ id, num, name, description, color, paymentForm }) {
  const currentUser = useSelector(getCurrentUserData());
  const group = useSelector(getGroupById(currentUser.teamId));
  const handleChange = (id) => {
    paymentForm(id);
  };
  return (
    <div className="flex flex-col">
      <div className={color}>
        <div className="p-1.5 w-full inline-block align-middle ">
          <div className="flex overflow-hidden justify-between items-center border rounded-lg ">
            <div className="grid grid-cols-8 auto-col-max">
              <div className="col-span-1 px-6 py-2 text-xs text-blue-800">
                {num}
              </div>
              <div className="hidden sm:flex col-span-2 px-6 py-2 text-xs text-blue-800">
                {name}
              </div>
              <div className="col-span-5 px-6 py-2 text-xs text-blue-800">
                {description}
              </div>
            </div>
            {color === "overflow-x-auto bg-blue-500 hover:bg-blue-300" ? (
              <button
                className="px-3 sm:w-40 h-10 rounded bg-lime-500 mr-5 hover:bg-lime-300 ease-in-out my-1"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={() => handleChange(id)}
                disabled={!group.missionId ? false : true}
              >
                payment
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

MissionLink.propTypes = {
  id: PropTypes.string,
  num: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
  paymentForm: PropTypes.func,
};

export default MissionLink;
