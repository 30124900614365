import React from "react";
import PropTypes from "prop-types";

const TextField = ({
  label,
  type,
  name,
  value,
  onChange,
  className,
  placeholder,
  autocomplete,
  error,
}) => {
  const handleChange = ({ target }) => {
    onChange({ name: target.name, value: target.value });
  };
  return (
    <div className="mt-4">
      <div className="input-group">
        <label>
          {label}
          <input
            type={type}
            id={name}
            name={name}
            autoComplete={autocomplete}
            value={value}
            onChange={handleChange}
            className={className}
            placeholder={placeholder}
          />
        </label>
        {error ? (
          <div className="h-5 text-red-400 text-sm">{error}</div>
        ) : (
          <div className="h-5"> </div>
        )}
      </div>
    </div>
  );
};
// TextField.defaultProps = {
//   type: "text",
// };
TextField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  label: PropTypes.string,
};

export default TextField;
