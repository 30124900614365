import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MissionLink from "../ui/MissionLink";
import { useSelector } from "react-redux";
import { getGroupById } from "../store/groups";
import { getMissions } from "../store/missions";
import { getCurrentUserData } from "../store/users";

function Missions({ paymentForm }) {
  const missions = useSelector(getMissions());
  const currentUser = useSelector(getCurrentUserData());
  const group = useSelector(getGroupById(currentUser.teamId));
  function ColorOfLink(id) {
    if (group.missionId === id) {
      return "overflow-x-auto bg-lime-500 hover:bg-lime-300";
    }
    if (group.missionId !== id) {
      return "overflow-x-auto bg-blue-500 hover:bg-blue-300";
    }
  }

  return (
    <>
      {missions &&
        missions.map((elem, index) => {
          return (
            <div className="flex flex-col mt-3 border-2" key={elem._id}>
              {!group.missionId ? (
                <MissionLink
                  num={index + 1}
                  id={elem._id}
                  name={elem.name}
                  description={elem.description}
                  color={ColorOfLink(elem._id)}
                  paymentForm={() => paymentForm(elem._id)}
                />
              ) : group.missionId !== elem._id ? (
                <MissionLink
                  num={index + 1}
                  id={elem._id}
                  name={elem.name}
                  description={elem.description}
                  color={ColorOfLink(elem._id)}
                  paymentForm={() => paymentForm(elem._id)}
                />
              ) : (
                <Link to={elem._id} state={{ _id: elem._id }}>
                  <MissionLink
                    num={index + 1}
                    id={elem._id}
                    name={elem.name}
                    description={elem.description}
                    color={ColorOfLink(elem._id)}
                  />
                </Link>
              )}
            </div>
          );
        })}
    </>
  );
}

Missions.propTypes = {
  list: PropTypes.array,
};

export default Missions;
