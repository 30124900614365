import httpService from "./http.service";

const missionsEndpoint = "missions/";

const missionsService = {
  get: async () => {
    const { data } = await httpService.get(missionsEndpoint);
    return data;
  },
};
export default missionsService;
