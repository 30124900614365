import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getTasksStatus, loadTasks } from "../../store/tasks";
import { loadStartList } from "../../store/start";
import { clearJob } from "../../store/job";

const TaskLoader = ({ children }) => {
  const dispatch = useDispatch();
  const taskStatus = useSelector(getTasksStatus());

  useEffect(() => {
    dispatch(loadTasks());
    dispatch(loadStartList());
    dispatch(clearJob());
  }, []);

  if (!taskStatus)
    return (
      <svg
        className="animate-spin h-5 w-5 mr-3 bg-black"
        viewBox="0 0 24 24"
      ></svg>
    );
  return children;
};

TaskLoader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default TaskLoader;
