import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "../components/common/form/TextField";
import { validator } from "../utils/validator";
import { useDispatch, useSelector } from "react-redux";
import { getAuthErrors, login } from "../store/users";
import CaptchaComponent from "../components/CaptchaComponent";

const LoginForm = memo(() => {
  const [data, setData] = useState({
    email: "",
    password: "",
    stayOn: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const loginError = useSelector(getAuthErrors());

  const handleChange = (target) => {
    setData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const handleCaptcha = () => {
    setData((prevState) => ({
      ...prevState,
      stayOn: true,
    }));
  };

  const validatorConfig = {
    email: {
      isRequired: {
        message: "Электронная почта обязательна для заполнения",
      },
      isEmail: {
        message: "Email введен некорректно",
      },
      max: {
        message: "Email слишком длинный",
        value: 32,
      },
    },
    password: {
      isRequired: {
        message: "Пароль обязателен для заполнения",
      },
      isCapitalSymbol: {
        message: "Пароль должен содержать хотя бы одну заглавную букву",
      },
      isContainDigit: {
        message: "Пароль должен содержать хотя бы одно число",
      },
      min: {
        message: "Пароль должен состоять минимум из 8 символов",
        value: 8,
      },
      max: {
        message: "Пароль до 25 знаков",
        value: 25,
      },
    },
    stayOn: {
      isRequired: {
        message: "Пройди капчу",
        value: true,
      },
    },
  };

  useEffect(() => {
    validate();
  }, [data]);

  const validate = () => {
    const errors = validator(data, validatorConfig);
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isValid = Object.keys(errors).length === 0;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) return;
    const redirect = navigate("/");
    dispatch(login({ payload: data, redirect }));
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit} className="rounded">
        <TextField
          label="Электронная почта"
          name="email"
          value={data.email}
          onChange={handleChange}
          error={errors.email}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <TextField
          label="Пароль"
          type="password"
          name="password"
          autocomplete="on"
          value={data.password}
          onChange={handleChange}
          error={errors.password}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />

        <div className="flex flex-col w-full justify-center">
          {!data.stayOn && (
            <CaptchaComponent onChange={handleCaptcha} error={errors.stayOn} />
          )}
        </div>

        {loginError && <p className="text-red-500 px-10">{loginError}</p>}
        <div className="flex justify-end mt-5">
          <input
            type="submit"
            value="Submit"
            className="focus:outline-none text-black bg-lime-500 hover:bg-lime-400 focus:ring-4 focus:ring-lime-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-lime-600 dark:hover:bg-lime-700 dark:focus:ring-lime-800"
            disabled={!isValid && (data.stayOn === true ? "" : "need verify")}
          />
        </div>
      </form>
    </div>
  );
});

export default LoginForm;
