import localStorageService from "./http.localStorage";
import httpService from "./http.service";

const fileEndpoint = "uploads/";

const fileService = {
  get: async (groupId, taskId) => {
    const { data } = await httpService.get(fileEndpoint, {
      params: {
        equalTo: `${groupId}`,
        taskId: `${taskId}`,
      },
    });
    return data;
  },
  upload: async (formData, taskId) => {
    const { data } = await httpService.post(
      fileEndpoint + localStorageService.getGroupId(),
      formData,
      {
        params: {
          taskId: `${taskId}`,
        },
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  },
};

export default fileService;
