import React from "react";
import PropTypes from "prop-types";
import Skills from "./skills";

const SkillsCard = ({ data }) => {
  return (
    <div className="flex flex-row">
      <p className="self-center mr-10">Skills:</p>

      <div className="card-text">
        {data !== undefined ? (
          <Skills skills={data} />
        ) : (
          <div>Хотелось бы увидеть ваши способности</div>
        )}
      </div>
    </div>
  );
};
SkillsCard.propTypes = {
  data: PropTypes.array,
};

export default SkillsCard;
