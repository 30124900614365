import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getSkillById } from "../../store/skills";

const Skill = ({ id }) => {
  const { _id, name, level } = useSelector(getSkillById(id));
  return (
    <span className={"badge m-1 bg-"} key={_id}>
      {name + " : " + level}
    </span>
  );
};
Skill.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Skill;
