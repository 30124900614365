import { createSlice } from "@reduxjs/toolkit";
import taskService from "../service/task.service";

const tasksSlice = createSlice({
  name: "tasks",
  initialState: {
    entities: null,
    isLoading: false,
    taskLoader: false,
    error: null,
  },
  reducers: {
    tasksRequested: (state) => {
      state.isLoading = true;
    },
    tasksReceived: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
      state.taskLoader = true;
    },
    tasksRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    tasksLogOut: (state) => {
      state.entities = null;
      state.isLoading = false;
      state.taskLoader = false;
    },
  },
});

const { reducer: tasksReducer, actions } = tasksSlice;
const { tasksRequested, tasksReceived, tasksRequestFailed, tasksLogOut } =
  actions;

export const loadTasks = () => async (dispatch) => {
  dispatch(tasksRequested());
  try {
    const { content } = await taskService.get();
    dispatch(tasksReceived(content));
  } catch (error) {
    dispatch(tasksRequestFailed(error.message));
  }
};

export const logOutTask = () => (dispatch) => {
  dispatch(tasksLogOut());
};

export const getTaskById = (taskId) => (state) => {
  if (state.tasks.entities) {
    return state.tasks.entities.find((u) => u._id === taskId);
  }
};

export const getTasksStatus = () => (state) => state.tasks.taskLoader;

export default tasksReducer;
