import { createSlice } from "@reduxjs/toolkit";
import locationService from "../service/location.service";
const geolocation = require("geolocation");

const locationsSlice = createSlice({
  name: "locations",
  initialState: {
    entities: null,
    userLocation: null,
    isLoading: false,
    error: null,
    dataLoading: false,
  },
  reducers: {
    locationsRequested: (state) => {
      state.isLoading = true;
    },
    locationUserReceived: (state, action) => {
      state.userLocation = action.payload;
      state.dataLoading = true;
      state.isLoading = false;
    },
    locationsRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    locationsLoggedOut: (state) => {
      state.entities = null;
      state.userLocation = null;
      state.dataLoading = false;
    },
  },
});

const { reducer: locationsReducer, actions } = locationsSlice;
const {
  locationsRequested,
  locationsReceived,
  locationUserReceived,
  locationsRequestFailed,
} = actions;

export const LoadLocationByTaskId = (taskId) => async (dispatch) => {
  dispatch(locationsRequested());
  try {
    const { content } = await locationService.get(taskId);
    dispatch(locationsReceived(content));
  } catch (error) {
    dispatch(locationsRequestFailed(error.message));
  }
};

export const LoadUserLocation = () => async (dispatch) => {
  dispatch(locationsRequested());
  try {
    geolocation.getCurrentPosition(function (err, position) {
      if (err) dispatch(locationsRequestFailed(err.message));
      dispatch(
        locationUserReceived({
          latitude: Number(position.coords.latitude),
          longitude: Number(position.coords.longitude),
        })
      );
    });
  } catch (error) {
    dispatch(locationsRequestFailed(error.message));
  }
};

export const getLocation = () => (state) => state.locations.entities;
export const getLocationStatus = () => (state) => state.locations.dataLoading;
export const getUserLocation = () => (state) => state.locations.userLocation;

export default locationsReducer;
