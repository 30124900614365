import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCurrentUserData } from "../store/users";
import localStorageService from "../service/http.localStorage";

function NavProfile() {
  const currentUser = useSelector(getCurrentUserData());
  const [isOpen, setOpen] = useState(false);
  const toggleMenu = () => {
    setOpen((prevState) => !prevState);
  };

  const handleClear = () => {
    localStorageService.removeAuthData();
  };

  if (!currentUser)
    return (
      <Link to="login">
        <button
          className="px-5 h-10 rounded bg-lime-500 mr-5 hover:bg-lime-300"
          onClick={handleClear}
        >
          Login
        </button>
      </Link>
    );
  return (
    <div className="relative inline-block text-left" onMouseLeave={toggleMenu}>
      <button
        type="button"
        className="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-30  bg-lime-500 mr-5 hover:bg-lime-300"
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        onClick={toggleMenu}
      >
        {currentUser && currentUser.nickname}
      </button>
      {isOpen ? (
        <div
          className="absolute right-0 z-10 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1" role="none">
            <Link
              to={`user/${currentUser && currentUser._id}`}
              className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-50"
              onClick={toggleMenu}
            >
              Profile
            </Link>
            <Link
              to="logout"
              className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-50"
            >
              Log Out
            </Link>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default NavProfile;
