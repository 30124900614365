import React from "react";
import PropTypes from "prop-types";
import Skill from "./Skill";

const SkillsList = ({ skills }) => {
  return (
    <>
      {skills !== undefined
        ? skills.map((qual) => (
            <div key={qual}>
              <Skill key={qual} id={qual} />
            </div>
          ))
        : ""}
    </>
  );
};

SkillsList.propTypes = {
  skills: PropTypes.array,
};

export default SkillsList;
