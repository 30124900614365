import React, { useState, useEffect } from "react";
import Letter from "../ui/Letter";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPlay, updatePlay } from "../store/play";
import TaskLoader from "../components/hoc/taskLoader";
import Complete from "../components/Complete";
import configFile from "../config.json";
import { getStartByPlayId, updateStart } from "../store/start";
import { getCurrentGroup, updateGroup } from "../store/groups";
import { createPayment } from "../store/payment";
import { getCurrentUserData } from "../store/users";

function Go() {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUserData());
  const group = useSelector(getCurrentGroup());
  const play = useSelector(getPlay(group && group.playId));
  const start = useSelector(getStartByPlayId(play && play._id));
  const navigate = useNavigate();
  const [payToAdmin] = useState({
    payer: currentUser._id,
    sum: -15000,
    missionId: group.missionId,
    teamId: group._id,
    writeOff: true,
  });

  function gameOver(play) {
    dispatch(createPayment(payToAdmin));
    dispatch(updatePlay(play));
    dispatch(updateGroup({ ...group, missionId: null, playId: null }));
  }
  useEffect(() => {
    const timeNow = new Date().getTime();
    if (
      play &&
      Date.parse(play.created_at) + Number(configFile.REACT_APP_PLAY_TIME) <
        timeNow
    ) {
      gameOver({ ...play, timeLock: timeNow });
    }
  }, [play]);

  useEffect(() => {
    const timeNow = new Date().getTime();
    if (
      start &&
      Number(start.time) + Number(configFile.REACT_APP_START_TIME) < timeNow
    ) {
      dispatch(updateStart({ ...start, timeLock: timeNow }));
      gameOver({ ...play, timeLock: timeNow });
    }
  }, [start]);

  const handleCheck = () => {
    // списание средств по завершении игры
    gameOver(play);
    navigate(`../user/${currentUser._id}`);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <TaskLoader>
        <div className="flex flex-col px-3">
          {start ? (
            <Complete start={start} play={play} />
          ) : (
            <>
              <div className="block text-center">
                <div className="mt-10 mb-6 font-medium text-lg">
                  Hallo friend...
                </div>
                <div className="mt-10">
                  Задание будет выполняться в пределах границ выбранного города.
                </div>
                <div className="mb-20 sm:px-40">
                  <div className="my-4">
                    Перед вами окна за которыми скрыты миссии. Нажав на любое
                    окно вы приступаете к выполнению задания. Учитывайте время
                    выполнения каждого задания 24 часа. После выполнения задания
                    вы приступаете к выполнению следующего задания.
                  </div>
                </div>
                <div className="items-center align-middle">
                  <div>
                    <div className="flex justify-center">
                      {play &&
                        play.secret
                          .split("")
                          .map((elem, index) => (
                            <Letter
                              key={index}
                              letter={elem.toString()}
                              number={index}
                              taskId={play.tasks[index]}
                              start={start}
                            />
                          ))}
                    </div>
                    <div className="flex justify-center mt-10">
                      <h1>Выполняй задания в любом порядке</h1>
                    </div>
                  </div>
                </div>
              </div>
              {play && play.endPlay && (
                <div className="flex justify-center my-10">
                  <button
                    className="bg-green-400 text-gray-700 block px-10 py-2 text-sm hover:bg-green-500 border-spacing-2 border-orange-400 border-2 hover:border-green-300 rounded-md"
                    onClick={handleCheck}
                  >
                    game over
                  </button>
                </div>
              )}
              <div className="flex justify-end my-10">
                <button
                  className="bg-green-400 text-gray-700 block px-10 py-2 text-sm hover:bg-green-500 border-spacing-2 border-orange-400 border-2 hover:border-green-300 rounded-md"
                  onClick={handleGoBack}
                >
                  back
                </button>
              </div>
              <div className="block mt-20 justify-between">
                <div className="font-medium">Подсказки:</div>
                <ul>
                  <li>Если задание ввело в тупик позови напарника</li>
                  <li>Задание может быть полученно на любом языке мира</li>
                  <li>Задание может предполагать переноску тяжелых грузов</li>
                  <li>
                    На любое действие вы должны иметь видео подтверждение,
                    включая окончание выполнения задания
                  </li>
                  <li>
                    При обнаружении Контакт-точки сделать видео обзор
                    местоположения, получить задание, записать видео выполнения
                    полученного задания.
                  </li>
                  <li>
                    Все ролики предоставленные в игре являются собственностью
                    ИГРЫ GO. Выбросы роликов в социальные сети, в youtube, иные
                    общедоступные ресурсы производятся только с указанием ссылки
                    на сайт игры GO, в противном случае администрация игры
                    оставляет за собой право блокировки.
                  </li>
                  <li>
                    В игре отцениваются все ваши способности и достижения,
                    которые вы можете продемонстрировать в ваших видео.
                  </li>
                  <li>Снимай свои видосы в процессе игры, кайфуй от игры</li>
                  <li>Внимательно читайте задания</li>
                  <li>
                    Самый лудший контент будет опубликован на ютуб канале игры.
                    Не забывайте главное получить тот самый адреналин и улыбку
                    от прожитых мгновений.
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </TaskLoader>
    </>
  );
}

export default Go;
