import { createSlice } from "@reduxjs/toolkit";
import missionsService from "../service/missions.service";

const missionsSlice = createSlice({
  name: "missions",
  initialState: {
    entities: null,
    isLoading: false,
    missionsLoader: false,
    error: null,
  },
  reducers: {
    missionsRequested: (state) => {
      state.isLoading = true;
    },
    missionsReceived: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
      state.missionsLoader = true;
    },
    missionsRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    missionLogOut: (state, action) => {
      state.entities = null;
      state.isLoading = false;
      state.missionsLoader = false;
    },
  },
});

const { reducer: missionsReducer, actions } = missionsSlice;
const {
  missionsRequested,
  missionsReceived,
  missionsRequestFailed,
  missionLogOut,
} = actions;

export const loadMissionsList = () => async (dispatch) => {
  dispatch(missionsRequested());
  try {
    const { content } = await missionsService.get();
    dispatch(missionsReceived(content));
  } catch (error) {
    dispatch(missionsRequestFailed(error.message));
  }
};

export const logOutMission = () => (dispatch) => {
  dispatch(missionLogOut());
};

export const getMissions = () => (state) => state.missions.entities;
export const getMissionsLoadingStatus = () => (state) =>
  state.missions.isLoading;
export const getMissionById = (id) => (state) => {
  if (state.missions.entities) {
    return state.missions.entities.find((p) => p._id === id);
  }
};

export const getMissionsStatus = () => (state) => state.missions.missionsLoader;

export default missionsReducer;
