import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getStartStatus, loadStartList } from "../../store/start";

const StartLoader = ({ children }) => {
  const dispatch = useDispatch();
  const startStatus = useSelector(getStartStatus());
  useEffect(() => {
    dispatch(loadStartList());
  }, []);

  if (!startStatus)
    return (
      <svg
        className="animate-spin h-5 w-5 mr-3 bg-black"
        viewBox="0 0 24 24"
      ></svg>
    );
  return children;
};

StartLoader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default StartLoader;
