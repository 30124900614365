import React from "react";

const LocationCheck = ({ handleJob }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleJob();
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit} className="bg-white rounded">
        <div className="flex justify-end mt-5">
          <input
            type="submit"
            value="Get task"
            className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          />
        </div>
      </form>
    </div>
  );
};

export default LocationCheck;
