import localStorageService from "./http.localStorage";
import httpService from "./http.service";

const taskEndpoint = "task/";

const taskService = {
  get: async () => {
    const { data } = await httpService.get(
      taskEndpoint + localStorageService.getUserId(),
      {
        params: {
          equalTo: `${localStorageService.getGroupId()}`,
        },
      }
    );
    return data;
  },
};

export default taskService;
