import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Form from "./common/form/Form";
import { useDispatch, useSelector } from "react-redux";
import { updatePlay } from "../store/play";
import { uploadFile } from "../store/fileUpload";
import { removeStartElem, updateStart } from "../store/start";
import StartLoader from "./hoc/startLoader";
import { CheckService } from "../utils/checkSecret";
import { getCurrentGroup } from "../store/groups";
import { GoArrowRight } from "react-icons/go";

function Task() {
  const dispatch = useDispatch();

  const group = useSelector(getCurrentGroup());

  const [videoFiles, setVideoFiles] = useState([]);

  const location = useLocation();
  const { number, task, play, start } = location.state;

  const { isLoading, error } = useSelector((state) => state.fileUpload);

  const navigate = useNavigate();

  const saveWord = (letter, num) => {
    const newSecret = play.secret
      .split("")
      .map((elem, index) => (index === Number(num) ? (elem = letter) : elem))
      .join("");
    const check = CheckService.checkSecret(newSecret);

    dispatch(
      updatePlay({
        ...play,
        secret: newSecret,
        endPlay: !check ? false : true,
      })
    );
  };

  const handleSave = (lett, num) => {
    saveWord(lett, num);
    dispatch(removeStartElem(start._id));
    navigate(-2);
  };

  const handleLoadVideo = (e) => {
    setVideoFiles(e.target.files[0]);
  };

  const handleUpload = () => {
    if (videoFiles) {
      dispatch(uploadFile(videoFiles, task._id));
      setVideoFiles([]);
    }
  };

  return (
    <>
      <StartLoader>
        <div className="w-full text-lg sm:text-lg">
          <div className="py-5">
            <div className="text-lg font-medium text-center py-5">
              {task.name}
            </div>
            <div className="border-solid border-4">
              <div className="mx-3 sm:mx-10 justify-between bg-lime-100 hover:bg-lime-50 py-5 px-10 ring-4 hover:ring-neutral-400 my-5 rounded-sm">
                {task.description}
              </div>

              <div className="bg-slate-100 py-10">
                <h2 className="flex justify-center font-bold">
                  Общая информация
                </h2>
                <div className="mx-3 sm:mx-10 justify-between py-3">
                  Если это первое задание команды в миссии сделайте видео обзор
                  вашей команды с озвучкой названия подразделения, и имена или
                  никнеймы. Выполняя задание записывайте видео ваших действий,
                  такие как описать местоположение Контакт-точки, видео
                  выполнения задания полученного на точке и другие по желанию.
                  Любые видео обеспечивающие подтверждение выполнения задания,
                  но не более трех файлов и размер файлов, не более 1000000 Mb.
                  Выполнив задание приступайте к следующему заданию. Неудачно
                  выполненное задание оценивается в любом случае, грузите тот
                  ролик, который по вашему мнению лудший. При загрузке контента
                  не касающегося игры администрация оставляет за собой право
                  ликвидации команды.
                </div>
              </div>
            </div>
          </div>
          {start && !start.check && (
            <div className="flex flex-col">
              <div className="flex justify-center py-10">
                <Form
                  taskId={task._id}
                  videoFiles={videoFiles}
                  handleUpload={handleUpload}
                  handleLoadVideo={handleLoadVideo}
                  isLoading={isLoading}
                  playId={play._id}
                  start={start}
                  error={error}
                />
              </div>
            </div>
          )}
          {start && start.check ? (
            <div>
              <button
                type="button"
                onClick={() => handleSave("0", number)}
                className="w-40 h-10 m-5 rounded bg-green-400 mr-5 hover:bg-green-500"
              >
                завершить
              </button>
            </div>
          ) : (
            <div className="flex flex-col sm:flex-row items-center">
              <div className="flex justify-center m-5 p-2">
                жми если вышел на точку
                <div className="py-1 px-3">
                  <GoArrowRight />
                </div>
              </div>

              <Link
                to={`/job/${group?.missionId}`}
                className="w-40 h-10 m-5 rounded bg-green-400 mr-5 hover:bg-green-500"
              >
                на точке
              </Link>
            </div>
          )}
        </div>
      </StartLoader>
    </>
  );
}

Task.propTypes = {};

export default Task;
