import React from "react";
import CreateTeamPage from "../../../page/createTeamPage";
import { useSelector } from "react-redux";
import { getCurrentUserInvitation } from "../../../store/invitations";
import PropTypes from "prop-types";

const CreateTeamModal = ({ onShowModal }) => {
  const invitation = useSelector(getCurrentUserInvitation());
  const handleClick = () => {
    onShowModal(false);
  };

  return (
    <>
      <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex justify-center mt-2 pt-10 pb-2 border-b border-solid border-slate-200 bg-lime-100 rounded-t">
              <h3 className="text-xl font-bold">Create team</h3>
            </div>
            {/*body*/}
            {invitation !== undefined ? (
              <div className="flex justify-center p-4">
                <p className="px-10 font-bold">У Вас есть приглашение</p>
              </div>
            ) : (
              <div className="flex justify-center p-4">
                <CreateTeamPage />
              </div>
            )}

            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-300 hover:text-red-500 bg-slate-100 hover:bg-slate-200 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 rounded-md"
                type="button"
                onClick={handleClick}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

CreateTeamModal.propTypes = {
  onShowModal: PropTypes.func,
};

export default CreateTeamModal;
