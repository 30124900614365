import { createAction, createSlice } from "@reduxjs/toolkit";
import invitationService from "../service/invitation.service";
import localStorageService from "../service/http.localStorage";

const invitationsSlice = createSlice({
  name: "invitation",
  initialState: {
    entities: null,
    isLoading: true,
    error: null,
  },
  reducers: {
    invitationsRequested: (state) => {
      state.isLoading = true;
    },
    invitationsReceived: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
    },
    invitationsRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    invitationCreated: (state, action) => {
      if (!Array.isArray(state.entities)) {
        state.entities = [];
      }
      state.entities.push(action.payload);
      state.isLoading = false;
    },
    invitationRemoved: (state, action) => {
      state.entities = state.entities.filter(
        (c) => c._id !== action.payload._id
      );
      state.isLoading = false;
    },
    invitationCleanCheck: (state, action) => {
      state.checkInvitation = action.payload;
      state.isLoading = false;
    },
    invitationLogOut: (state) => {
      state.entities = null;
      state.inventUser = null;
      state.checkInvitation = {
        memberTwoInv: false,
        memberThreeInv: false,
      };
    },
  },
});

const { reducer: invitationsReducer, actions } = invitationsSlice;

const {
  invitationsRequested,
  invitationsReceived,
  invitationCheck,
  invitationCreated,
  invitationsRequestFailed,
  invitationRemoved,
  invitationLogOut,
} = actions;

const addInvitationRequested = createAction(
  "invitation/addInvitationRequested"
);

export const loadInvitationList = () => async (dispatch) => {
  dispatch(invitationsRequested());
  try {
    const { content } = await invitationService.getInvitations();
    dispatch(invitationsReceived(content));
  } catch (error) {
    dispatch(invitationsRequestFailed(error.message));
  }
};

export const checkInvitation = (email, typeName) => async (dispatch) => {
  dispatch(invitationsRequested());
  try {
    const { content } = await invitationService.get(email);
    console.log("error invitation", content);
    dispatch(invitationCheck({ content: content.to, typeName: typeName }));
  } catch (error) {
    dispatch(invitationsRequestFailed(error.message));
  }
};

export const createInvitation = (payload) => async (dispatch) => {
  dispatch(addInvitationRequested(payload));
  try {
    const { content } = await invitationService.create(payload);
    dispatch(invitationCreated(content));
  } catch (error) {
    dispatch(invitationsRequestFailed(error.message));
  }
};

export const deleteInvitation = () => async (dispatch) => {
  dispatch(invitationsRequested());
  try {
    const { content } = await invitationService.delete();
    dispatch(invitationRemoved(content));
  } catch (error) {
    dispatch(invitationsRequestFailed(error.message));
  }
};

export const getCurrentUserInvitation = () => (state) => {
  const userId = localStorageService.getUserId();
  if (state.invitation.entities) {
    return state.invitation.entities.find((elem) => elem.to === userId);
  }
};

export const getInvitationById = (userId) => (state) => {
  if (state.invitation.entities) {
    return state.invitation.entities.find((elem) => elem.to === userId);
  }
};

export const logOutInvitation = () => (dispatch) => {
  dispatch(invitationLogOut());
};

export default invitationsReducer;
