import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentGroup } from "../../store/groups";
import { getMissionsStatus, loadMissionsList } from "../../store/missions";

const MissionLoader = ({ children }) => {
  const dispatch = useDispatch();
  const missionsStatus = useSelector(getMissionsStatus());
  const group = useSelector(getCurrentGroup());

  useEffect(() => {
    dispatch(loadMissionsList());
  }, [group]);

  if (!missionsStatus)
    <div className="w-full h-full">
      <p>Loading missions...</p>
    </div>;
  return children;
};

MissionLoader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default MissionLoader;
