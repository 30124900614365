import { useCallback } from "react";
import PropTypes from "prop-types";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import captchaService from "../service/captcha.service";
import { toast } from "react-toastify";

const CaptchaComponent = ({ onChange, error }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("checkCaptcha");
    try {
      const { content } = await captchaService.post(token);
      if (content[0].success) {
        onChange();
      }
    } catch (error) {
      toast(error.message);
    }
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  return (
    <>
      <button
        className="px-3 py-2 border-spacing-2 border rounded-md bg-slate-300"
        onClick={handleReCaptchaVerify}
      >
        Verify captcha
      </button>
      <div className="text-red-400 text-sm">{error}</div>
    </>
  );
};

CaptchaComponent.propTypes = {
  onChange: PropTypes.func,
  error: PropTypes.string,
};

export default CaptchaComponent;
