import { createAction, createSlice } from "@reduxjs/toolkit";
import startService from "../service/start.service";

const startSlice = createSlice({
  name: "start",
  initialState: {
    entities: null,
    isLoading: false,
    startLoader: false,
    error: null,
  },
  reducers: {
    startRequested: (state) => {
      state.isLoading = true;
    },
    startReceived: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
      state.startLoader = true;
    },
    startRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    startCreate: (state, action) => {
      if (!Array.isArray(state.entities)) {
        state.entities = [];
      }
      state.entities.push(action.payload);
    },
    startDeleteSuccessed: (state, action) => {
      state.entities = state.entities.filter(
        (u) => u._id !== action.payload._id
      );
      state.isLoading = false;
    },
    startUpdateSuccessed: (state, action) => {
      state.entities[
        state.entities.findIndex((u) => u._id === action.payload._id)
      ] = action.payload;
    },
    startLoggedOut: (state) => {
      state.entities = null;
      state.isLoading = false;
      state.startLoader = false;
    },
  },
});

const { reducer: startReducer, actions } = startSlice;
const {
  startRequested,
  startReceived,
  startRequestFailed,
  startCreate,
  startLoggedOut,
  startDeleteSuccessed,
  startUpdateSuccessed,
} = actions;

const addStartRequested = createAction("start/addStartRequested");
const startUpdateRequested = createAction("start/startUpdateRequested");
const removeStartRequested = createAction("start/removeStartRequested");

export const loadStartList = () => async (dispatch) => {
  dispatch(startRequested());
  try {
    const { content } = await startService.get();

    dispatch(startReceived(content));
  } catch (error) {
    dispatch(startRequestFailed(error.message));
  }
};

export const createStart = (payload) => async (dispatch) => {
  dispatch(addStartRequested(payload));
  try {
    const { content } = await startService.create(payload);
    dispatch(startCreate(content));
  } catch (error) {
    dispatch(startRequestFailed(error.message));
  }
};

export const updateStart = (payload) => async (dispatch) => {
  dispatch(startUpdateRequested());
  try {
    const { content } = await startService.update(payload);
    dispatch(startUpdateSuccessed(content));
  } catch (error) {
    dispatch(startRequestFailed(error.message));
  }
};

export const removeStartElem = (deletedElem) => async (dispatch) => {
  dispatch(removeStartRequested());

  if (deletedElem) {
    try {
      const { content } = await startService.delete(deletedElem);

      dispatch(startDeleteSuccessed(content));
    } catch (error) {
      dispatch(startRequestFailed(error.message));
    }
  }
};

export const getStartByPlayId = (playId) => (state) => {
  if (state.start.entities && playId) {
    return state.start.entities.find((u) => u.playId === playId);
  }
};

export const getStartStatus = () => (state) => state.start.startLoader;

export const loggedOutStart = () => (dispatch) => {
  dispatch(startLoggedOut());
};

export default startReducer;
