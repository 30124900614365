export function updatePayload(payload) {
  if (payload.status !== "play") {
    const findAccept = payload.members.find((elem) => {
      return elem.accept === false;
    });

    let newPayload = {
      ...payload,
      status: findAccept !== undefined ? "build" : "play",
    };
    return newPayload;
  } else {
    return payload;
  }
}
