export const checkGroupPayments = (arr, missionId) => {
  if (arr.length > 0) {
    const checkMissions = arr.filter((elem) => {
      return elem.missionId === missionId && elem;
    });
    const valueArr = checkMissions.map((elem) => elem.sum);
    const result = valueArr.reduce((acc, cur) => acc + cur, 0);

    return result;
  }
};
