import React from "react";
import PropTypes from "prop-types";
import FileLoader from "../../hoc/fileLoader";
import { useDispatch, useSelector } from "react-redux";
import { getFileUpload } from "../../../store/fileUpload";
import { updateStart } from "../../../store/start";
import { useNavigate } from "react-router-dom";

function Form({
  playId,
  taskId,
  isLoading,
  videoFiles,
  handleLoadVideo,
  start,
  handleUpload,
  error,
}) {
  const files = useSelector(getFileUpload(playId, taskId));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCheck = () => {
    dispatch(updateStart({ ...start, check: true }));
    navigate(-1);
  };
  return (
    <FileLoader>
      <div className="mx-3">
        {files.length <= 2 && (
          <form className="flex flex-col items-center">
            <label
              htmlFor="fileUpload"
              className="pr-2 font-serif text-lg font-bold py-3"
            >
              Загрузи видео (mkv, .mov, .mp4, .avi)
            </label>

            <input
              id="fileUpload"
              multiple
              name="video"
              type="file"
              accept=".mkv, .mov, .mp4, .avi"
              onChange={handleLoadVideo}
            />
          </form>
        )}
        {videoFiles && videoFiles.length !== 0 && files.length <= 2 ? (
          <div className="flex w-full justify-center">
            <button
              onClick={handleUpload}
              className="px-5 py-2 m-5 rounded bg-green-400 mr-5 hover:bg-green-500"
              disabled={isLoading}
            >
              upload
            </button>
          </div>
        ) : (
          <div className="p-5">
            Проверьте файл загрузки на качество и загрузите файл. Данные файлы
            являются подтверждением выполнения Вами задания. Удачи в игре.
          </div>
        )}
        {isLoading && <p>Uploading...</p>}

        {files &&
          files.map((elem, index) => (
            <p key={index} className="bg-green-200 px-2 py-1">
              File Uploaded: {elem.filename}
            </p>
          ))}
        {error && <p className="text-red-300 ">Error: {error}</p>}
        <div className="flex flex-col justify-center">
          <div className="mx-3">
            Напоминаем, каждое выполненное задание, это видеоответ, который вы
            загружаете. Если задание выполнено и загруженны все видео файлы
            жмите выполнено.
          </div>
          <button
            type="button"
            onClick={handleCheck}
            className="w-40 h-10 m-5 rounded bg-green-400 mr-5 hover:bg-green-500"
            disabled={files && files.length > 0 ? false : true}
          >
            выполнено
          </button>
        </div>
      </div>
    </FileLoader>
  );
}

Form.propTypes = {
  videoFiles: PropTypes.object || null,
  handleLoadVideo: PropTypes.func,
  handleUpload: PropTypes.func,
};

export default Form;
