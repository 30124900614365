import React, { useEffect, useState } from "react";
import { validator } from "../utils/validator";
import TextField from "../components/common/form/TextField";
import RadioField from "../components/common/form/RadioField";
import CheckBoxField from "../components/common/form/CheckBoxField";
import { useDispatch, useSelector } from "react-redux";
import SelectField from "../components/common/form/SelectField";
import { getSpecialties } from "../store/specialties";
import { signUp } from "../store/users";
import { getLevelOne } from "../store/levels";
import { useNavigate } from "react-router-dom";
import CaptchaComponent from "../components/CaptchaComponent";

const RegisterForm = () => {
  const dispatch = useDispatch();
  const level = useSelector(getLevelOne());
  const navigate = useNavigate();

  //server error 500 on levels
  const [data, setData] = useState({
    email: "",
    password: "",
    nickname: "",
    phoneNumber: "",
    teamId: "",
    level: level && level._id,
    sex: "male",
    skill: [],
    specialty: "",
    license: false,
    stayOn: false,
  });

  const [isOpen, setIsOpen] = useState(false);
  const specialties = useSelector(getSpecialties());
  const [errors, setErrors] = useState({});

  const selectSpecialties = specialties.map((elem) => ({
    value: elem._id,
    label: elem.name,
  }));

  const handleChange = (target) => {
    setData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const handleOpen = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const validatorConfig = {
    email: {
      isRequired: {
        message: "Электронная почта обязательна для заполнения",
      },
      isEmail: {
        message: "Email введен некорректно",
      },
      max: {
        message: "Email слишком длинный",
        value: 32,
      },
    },
    nickname: {
      isRequired: {
        message: "Nickname обязателен для заполнения",
      },
      isContainDigitEndLetter: {
        message: "Содержит только буквы и цифры",
      },
      min: {
        message: "Nickname должен состоять минимум из 3 символов",
        value: 3,
      },
      max: {
        message: "До 20 знаков",
        value: 15,
      },
    },
    phoneNumber: {
      isRequired: {
        message: "Номер телефона обязателен для заполнения",
      },
      max: {
        message: "Номер телефона указан неверно",
        value: 10,
      },
    },
    password: {
      isRequired: {
        message: "Пароль обязателен для заполнения",
      },
      isCapitalSymbol: {
        message: "Пароль должен содержать хотя бы одну заглавную букву",
      },
      isContainDigit: {
        message: "Пароль должен содержать хотя бы одно число",
      },
      min: {
        message: "Пароль должен состоять минимум из 8 символов",
        value: 8,
      },
      max: {
        message: "Пароль до 25 знаков",
        value: 25,
      },
    },
    specialty: {
      isRequired: {
        message: "Обязательно выберите вашу специальность",
      },
    },
    license: {
      isRequired: {
        message:
          "Вы не можете использовать наш сервис без подтверждения соглашения",
      },
    },
    stayOn: {
      isRequired: {
        message: "Пройди капчу",
        value: true,
      },
    },
  };
  useEffect(() => {
    validate();
  }, [data]);

  const validate = () => {
    const errors = validator(data, validatorConfig);
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const isValid = Object.keys(errors).length === 0;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) return;
    try {
      const newData = {
        email: data.email,
        password: data.password,
        nickname: data.nickname,
        phoneNumber: data.phoneNumber,
        teamId: data.teamId,
        level: data.level,
        sex: data.sex,
        skill: data.skill,
        specialty: data.specialty,
        license: data.license,
      };
      dispatch(signUp(newData));
      navigate("/");
    } catch (error) {
      setErrors(error);
    }
  };

  const handleCaptcha = () => {
    setData((prevState) => ({
      ...prevState,
      stayOn: true,
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="m-5">
      <TextField
        label="Электронная почта"
        name="email"
        type="text"
        value={data.email}
        onChange={handleChange}
        error={errors.email}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        placeholder="go888@gmail.com"
      />
      <TextField
        label="Пароль"
        type="password"
        name="password"
        value={data.password}
        onChange={handleChange}
        error={errors.password}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      />
      <TextField
        label="Nickname"
        name="nickname"
        value={data.nickname}
        onChange={handleChange}
        error={errors.nickname}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        placeholder="GGG"
      />
      <TextField
        label="Mobile phone number"
        name="phoneNumber"
        value={data.phoneNumber}
        onChange={handleChange}
        error={errors.phoneNumber}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        placeholder="+77758889966"
      />
      <SelectField
        label="Выбери свою специальность"
        defaultOption="Choose..."
        options={selectSpecialties}
        name="specialty"
        onChange={handleChange}
        value={data.specialty}
        error={errors.specialty}
      />
      <RadioField
        options={[
          { name: "Male", value: "male" },
          { name: "Female", value: "female" },
          // { name: "Other", value: "other" },
        ]}
        value={data.sex}
        name="sex"
        onChange={handleChange}
        label="Выберите ваш пол"
      />
      <div className="bg-slate-200 mt-5">
        <button
          className="w-full bg-green-200 py-2 px-5 rounded-md"
          onClick={handleOpen}
        >
          Ознакомиться
        </button>
        {isOpen && (
          <>
            <h2 className="flex justify-center pt-5">Соглашение</h2>

            <p className="p-5">
              Для использования текущего приложения (сайт) поставив галочку вы
              даете согласие и подтверждаете: <br />
              1. Даете разрешение на сбор и обработку данных. 2. Вы являетесь
              совершеннолетним или в вашей команде состоят ваши родители. 3.
              Ваше здоровье позволяет Вам заниматься спортом и получать
              нагрузки, которые могут быть получены в игре. 5. В видеороликах
              запрещено использовать мат и нецензурную лексику. 6. В
              видеороликах запрещено рекламировать, что либо если это не
              является частью задания. 7. Загрузка посторонних видеороликов в
              замен необходимых является нарушением. 8. В команде, где есть
              подростки от 16 до 18 лет должны быть двое взрослых при этом один
              из них должен быть родитель подростка. 9. Вы являетесь
              дееспособным согласно законов страны проведения игры. 10. Все
              права по видеороликам загружаемым в приложение принадлежат
              администрации игры. 11. Распространение роликов в социальных сетях
              и иных приложениях разрешено только с указанием ссылки на игру.
              12. Каждый участник несет самостоятельную ответственность за себя
              лично при выполнении заданий и за ребенка, если в игре участвуют
              подростки от 16 лет, в команде. 13. Администрация имеет право
              удалить команду и ее участников, если нарушены перечисленные
              правила без объяснения причин. 14. Администрация имеет право
              наложить запрет на социальные аккаунты использующие контент в
              разрез текущих правил. 15. Указанные контактные данные
              соответствуют действительности и принадлежат Вам на праве
              собственности. 16. Участники обязуются использовать сильные пароли
              с присутствием знаков - * + ! № % $ &, букв и цифр. 17.
              Администрация имеет право запросить дополнительные данные если
              возникают сомнения по родительским правам. 18. Вы понимаете, что
              для выполнения задания необходимо много ходить и решать
              нестандартные задачи на камеру. 19. Приветственный ролик команды
              должен быть с лицами тех, кто принимаете участие в игре. 20. В
              случае выявления несоответствия данных игрока и данных видео
              изображения администрация имеет право принять меры в отношении
              команды.
            </p>
          </>
        )}
      </div>
      <CheckBoxField
        value={data.license}
        onChange={handleChange}
        name="license"
        error={errors.license}
      >
        Подтвердить соглашение
      </CheckBoxField>

      <div className="flex flex-col w-full justify-center">
        <CaptchaComponent onChange={handleCaptcha} error={errors.stayOn} />
      </div>
      <div className="flex justify-end mt-5">
        <input
          type="submit"
          value="Submit"
          className="focus:outline-none text-black bg-lime-500  hover:bg-lime-400 focus:ring-4 focus:ring-lime-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-lime-600 dark:hover:bg-lime-700 dark:focus:ring-lime-800"
          disabled={!isValid}
        />
      </div>
    </form>
  );
};

export default RegisterForm;
