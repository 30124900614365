import localStorageService from "./http.localStorage";
import httpService from "./http.service";

const paymentEndpoint = "payment/";

const paymentService = {
  create: async (payload) => {
    const { data } = await httpService.post(
      paymentEndpoint + localStorageService.getUserId(),
      payload
    );
    return data;
  },
  getPayments: async () => {
    const { data } = await httpService.get(
      paymentEndpoint + localStorageService.getUserId()
    );
    return data;
  },
  getOne: async (id) => {
    const { data } = await httpService.get(paymentEndpoint + id);
    return data;
  },
};

export default paymentService;
