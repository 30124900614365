import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

const Pagination = ({ itemsCount, pageSize, onPageChange, currentPage }) => {
  const pageCount = Math.ceil(itemsCount / pageSize);
  if (pageCount === 1) return null;
  const pages = _.range(1, pageCount + 1);
  return (
    <nav
      className="isolate inline-flex -space-x-px rounded-md shadow-sm"
      aria-label="Pagination"
    >
      {pages.map((page) => (
        <div
          key={"page_" + page}
          aria-current="page"
          className={`relative inline-flex items-center rounded-md px-2 py-2 mx-1 text-gray-400 hover:bg-gray-200 focus:z-20 focus:outline-offset-0
            ${page === currentPage ? "bg-gray-300" : ""}`}
        >
          <button className="page-link p-2" onClick={() => onPageChange(page)}>
            {page}
          </button>
        </div>
      ))}
    </nav>
  );
};
Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default Pagination;
