import React, { useState } from "react";
import UserTable from "../ui/userTable";
import { useSelector } from "react-redux";
import { getCurrentUserId, getUsersList } from "../store/users";
import Pagination from "../components/common/pagination";
import { paginate } from "../utils/paginate";
import SearchStatus from "../ui/searchStatus";
import _ from "lodash";

function UsersListPage(props) {
  const users = useSelector(getUsersList());
  const currentUserId = useSelector(getCurrentUserId());
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSpecialty, setSelectedSpecialty] = useState();
  const pageSize = 20;
  const [sortBy, setSortBy] = useState({ path: "name", order: "asc" });

  const handleSort = (item) => {
    setSortBy(item);
  };

  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePagePrev = (itemsCount, pageSize) => {
    const pageCount = Math.ceil(itemsCount / pageSize);
    if (currentPage >= pageCount - 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageNext = (itemsCount, pageSize) => {
    const pageCount = Math.ceil(itemsCount / pageSize);
    if (currentPage <= pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (users) {
    function filterUsers(data) {
      const filteredUsers = searchQuery
        ? data.filter(
            (user) =>
              user.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
          )
        : selectedSpecialty
        ? data.filter(
            (user) =>
              JSON.stringify(user.specialty) ===
              JSON.stringify(selectedSpecialty)
          )
        : data;
      return filteredUsers.filter((u) => u._id !== currentUserId);
    }
    const filteredUsers = filterUsers(users);
    const count = filteredUsers.length;
    const sortedUsers = _.orderBy(filteredUsers, [sortBy.path], [sortBy.order]);

    const usersCrop = paginate(sortedUsers, currentPage, pageSize);
    return (
      <div className="text-lg">
        <div className="flex flex-col">
          <SearchStatus length={count} />
          {count > 0 && (
            <UserTable
              users={usersCrop}
              onSort={handleSort}
              selectedSort={sortBy}
            />
          )}
          <div className="flex flex-row justify-center my-5">
            <div className="relative inline-flex items-center rounded-l-md text-gray-400 hover:bg-gray-200 focus:z-20 focus:outline-offset-0">
              <button
                className="page-link py-4 px-2"
                onClick={() => handlePagePrev(count, pageSize)}
              >
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>

            <Pagination
              itemsCount={count}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
            <div className="relative inline-flex items-center rounded-r-md text-gray-400 hover:bg-gray-200 focus:z-20 focus:outline-offset-0">
              <button
                className="page-link py-4 px-2"
                onClick={() => handlePageNext(count, pageSize)}
              >
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return "loading...";
}

export default UsersListPage;
