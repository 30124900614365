import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getLocationStatus, LoadUserLocation } from "../../store/location";

const LocationLoader = ({ children }) => {
  const dispatch = useDispatch();
  const locationStatus = useSelector(getLocationStatus());

  useEffect(() => {
    dispatch(LoadUserLocation());
  }, []);

  if (!locationStatus)
    return (
      <svg className="animate-spin h-5 w-5 mr-3 bg-black" viewBox="0 0 24 24">
        0000
      </svg>
    );
  return children;
};

LocationLoader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default LocationLoader;
