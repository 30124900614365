import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getLevelById } from "../store/levels";

const Level = ({ id }) => {
  const level = useSelector(getLevelById(id));
  return <p className="flex justify-center">{level && level.name}</p>;
};
Level.propTypes = {
  id: PropTypes.string,
};
export default Level;
