import { createAction, createSlice } from "@reduxjs/toolkit";
import groupService from "../service/group.service";
import { updateUser } from "./users";
import { updatePayload } from "../utils/checkUpdateGroup";
import localStorageService from "../service/http.localStorage";

const groupsSlice = createSlice({
  name: "groups",
  initialState: {
    entities: null,
    isLoading: false,
    error: null,
    dataLoaded: false,
    isComplete: false,
  },
  reducers: {
    groupsRequested: (state) => {
      state.isLoading = true;
    },
    groupsReceived: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
      state.dataLoaded = true;
    },
    groupCreateReceived: (state, action) => {
      if (!Array.isArray(state.entities)) {
        state.entities = [];
      }
      state.entities.push(action.payload);
      state.isLoading = false;
    },
    groupsRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    groupLoggedOut: (state) => {
      state.entities = null;
      state.dataLoaded = false;
      state.isLoading = false;
      state.isComplete = false;
    },
    groupRemoved: (state, action) => {
      state.entities = state.entities.filter(
        (c) => c._id !== action.payload._id
      );
      state.isComplete = false;
    },
    groupsUpdateSuccessed: (state, action) => {
      state.entities[
        state.entities.findIndex((u) => u._id === action.payload._id)
      ] = action.payload;
      state.isComplete = action.payload.status === "play" ? true : false;
    },
  },
});

const { reducer: groupsReducer, actions } = groupsSlice;

const {
  groupsRequested,
  groupsReceived,
  groupsRequestFailed,
  groupCreateReceived,
  groupsUpdateSuccessed,
  groupLoggedOut,
  groupRemoved,
} = actions;

const addGroupRequested = createAction("groups/addGroupRequested");
const removeGroupRequested = createAction("groups/removeGroupRequested");

export const loadGroupsList = () => async (dispatch) => {
  dispatch(groupsRequested());
  try {
    const { content } = await groupService.get();
    dispatch(groupsReceived(content));
  } catch (error) {
    dispatch(groupsRequestFailed(error.message));
  }
};

export const getGroupByName = (name) => (state) => {
  if (state.groups.entities) {
    return state.groups.entities.find(
      (elem) => elem.teamname.toLowerCase() === name.toLowerCase()
    );
  }
};

export const deleteGroup = (payload) => async (dispatch) => {
  dispatch(removeGroupRequested());
  try {
    const { content } = await groupService.delete(payload);
    console.log("delete group", content);
    dispatch(groupRemoved(content));
  } catch (error) {
    dispatch(groupsRequestFailed(error.message));
  }
};

export const getGroupsList = () => (state) => state.groups.entities;

export const getCurrentGroup = () => (state) => {
  const groupId = localStorageService.getGroupId();
  if (state.groups.entities) {
    return state.groups.entities.find((u) => u._id === groupId);
  }
};

export const getGroupById = (groupId) => (state) => {
  if (state.groups.entities) {
    return state.groups.entities.find((u) => u._id === groupId);
  }
};

export const createGroup =
  ({ newData, currentUser }) =>
  async (dispatch) => {
    dispatch(addGroupRequested());
    try {
      const { content } = await groupService.create(newData);
      dispatch(groupCreateReceived(content));
      dispatch(updateUser({ ...currentUser, teamId: content._id }));
    } catch (error) {
      dispatch(groupsRequestFailed(error.message));
    }
  };

export const updateGroup = (payload) => async (dispatch) => {
  dispatch(groupsRequested());

  try {
    const newPayload = updatePayload(payload);
    console.log();
    const { content } = await groupService.update(newPayload);
    dispatch(groupsUpdateSuccessed(content));
  } catch (error) {
    dispatch(groupsRequestFailed(error.message));
  }
};

// export const updateGroupBefore = (payload) => async (dispatch) => {
//   dispatch(groupsRequested());
//   try {
//     const newPayload = updatePayload(payload);
//     const { content } = await groupService.updateGroup(newPayload);
//     dispatch(groupsUpdateSuccessed(content));
//   } catch (error) {
//     dispatch(groupsRequestFailed(error.message));
//   }
// };

export const logOutGroup = () => (dispatch) => {
  dispatch(groupLoggedOut());
};

export const getDataLoaded = () => (state) => state.groups.dataLoaded;

export const getGroupsLoadingStatus = () => (state) => state.groups.isLoading;

export default groupsReducer;
