import React from "react";
import PropTypes from "prop-types";
import { getMissionById } from "../../store/missions";
import { useSelector } from "react-redux";

const MissionGroupName = ({ id }) => {
  let mission = useSelector(getMissionById(id));

  return (
    <div className="flex justify-center">
      {mission !== undefined ? mission.name : ""}
    </div>
  );
};
MissionGroupName.propTypes = {
  status: PropTypes.string,
};

export default MissionGroupName;
