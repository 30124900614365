import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  getFileUploadStatus,
  loadFilesUploadList,
} from "../../store/fileUpload";
import { useParams } from "react-router-dom";

const FileLoader = ({ children }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const fileStatus = useSelector(getFileUploadStatus());

  useEffect(() => {
    dispatch(loadFilesUploadList(params.id));
  }, [params.id]);

  if (!fileStatus)
    return (
      <svg
        className="animate-spin h-5 w-5 mr-3 bg-black"
        viewBox="0 0 24 24"
      ></svg>
    );
  return children;
};

FileLoader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default FileLoader;
