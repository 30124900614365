import React, { useState } from "react";
import PropTypes from "prop-types";
import UserCard from "../../ui/userCard";
import SkillsCard from "../../ui/skillsCard";
import Button from "../../ui/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserById, updateUser } from "../../store/users";
import Results from "../../components/Results";
import { deleteGroup, getGroupById } from "../../store/groups";
import { createDeletedElem } from "../../store/delete";
import ConfirmationForm from "../../components/common/form/ConfirmationForm";

const UserPage = ({ userId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getUserById(userId));
  const group = useSelector(getGroupById(user && user?.teamId));

  const handleClick = () => {
    navigate("/");
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleConfirm = () => {
    const deletedMembers = group.members.filter((elem) => {
      return elem.member !== user._id;
    });

    dispatch(
      createDeletedElem({
        userId: deletedMembers[0].member,
        removeInvitation: false,
      })
    );
    dispatch(
      createDeletedElem({
        userId: deletedMembers[1].member,
        removeInvitation: false,
      })
    );
    dispatch(deleteGroup({ groupId: user.teamId }));
    dispatch(updateUser({ ...user, teamId: "" }));
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div className="container mb-10 text-lg">
      <div className="flex-row sm:mx-10 mt-24 border-solid border-2 border-indigo-600">
        <div className="grid gap-3 grid-cols-1 sm:grid-cols-3">
          <UserCard user={user} />
          <div className="col-span-2 m-5">
            <div className="flex flex-row">
              <p className="self-center mr-10">Team name:</p>
              <div className="text-secondary my-2">
                {user?.teamId ? group && group.teamname : "не состоит"}
              </div>
            </div>
            <SkillsCard data={user.skills} />
          </div>
        </div>
      </div>

      {group?.status === "play" && !group?.missionId && (
        <div className="flex flex-row justify-end mx-10 border-solid my-10">
          <div className="flex px-4 py-2 text">Удалить группу</div>
          <ConfirmationForm
            handleCancel={handleCancel}
            open={open}
            handleConfirm={handleConfirm}
          />
          <button
            className="px-6 py-2 rounded bg-red-500 mr-5 hover:bg-red-300"
            onClick={handleOpen}
          >
            delete
          </button>
        </div>
      )}

      {user?.teamId && <Results />}
      <div className="flex justify-center  my-20">
        <Button name="back" className=" w-40" handleClick={handleClick} />
      </div>
    </div>
  );
};

UserPage.propTypes = {
  userId: PropTypes.string,
};

export default UserPage;
