import React from "react";
import { Navigate, useParams } from "react-router-dom";
import UsersListPage from "../page/UsersListPage";
import UsersLoader from "../components/hoc/usersLoader";
import { useSelector } from "react-redux";
import { getCurrentUserData } from "../store/users";
import UserPage from "../page/userPage/userPage";
import EditUserPage from "../page/userPage/editUserPage";

function Users() {
  const { userId, edit } = useParams();
  const currentUser = useSelector(getCurrentUserData());

  return (
    <div className="w-full">
      <UsersLoader>
        {userId ? (
          edit ? (
            userId === currentUser._id ? (
              <EditUserPage />
            ) : (
              <Navigate to={`/users/${currentUser._id}/edit`} />
            )
          ) : (
            <UserPage userId={userId} />
          )
        ) : (
          <UsersListPage />
        )}
      </UsersLoader>
    </div>
  );
}

Users.propTypes = {};

export default Users;
