import React from "react";
import PropTypes from "prop-types";
import Table from "../components/common/table";
import MissionGroupName from "../components/common/MissionGroupName";

const PlayTable = ({ plays, onSort, selectedSort, ...rest }) => {
  const columns = {
    nickname: {
      path: "missions",
      name: "missions",
      component: (play) => <MissionGroupName id={play.missionId} />,
    },
    secret: {
      name: "secret",
      component: (play) => (
        <div className="flex justify-center">{play.secret}</div>
      ),
    },
    win: {
      name: "results",
      component: (play) => (
        <div className="flex justify-center">
          {play.win === undefined ? (
            <div>в процессе</div>
          ) : play.win ? (
            <div className="text-green-300">победа</div>
          ) : (
            <div className="text-red-300">поражение</div>
          )}
        </div>
      ),
    },
  };
  return (
    <Table
      onSort={onSort}
      selectedSort={selectedSort}
      columns={columns}
      data={plays}
    />
  );
};

PlayTable.propTypes = {
  plays: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
  selectedSort: PropTypes.object.isRequired,
};

export default PlayTable;
