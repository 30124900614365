import React, { useEffect, useState } from "react";
import AddToTeamModal from "../components/common/modal/AddToTeamModal";
import CreateTeamModal from "../components/common/modal/CreateTeamModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUserData,
  getIsLoggedIn,
  updateUser,
  verifySend,
} from "../store/users";
import {
  deleteInvitation,
  getCurrentUserInvitation,
} from "../store/invitations";
import { getGroupById } from "../store/groups";
import { getCurrentUserDeleteElem, removeDeletedElem } from "../store/delete";
import { useNavigate } from "react-router-dom";

function Main() {
  const [showModal, setShowModal] = useState({ create: false, add: false });
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn());

  const navigate = useNavigate();

  const currentUser = useSelector(getCurrentUserData());

  const onShowModal = () => {
    if (showModal === false) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  const deletedElem = useSelector(getCurrentUserDeleteElem());
  const group = useSelector(getGroupById(currentUser && currentUser.teamId));
  const invitation = useSelector(getCurrentUserInvitation());
  useEffect(() => {
    if (deletedElem !== undefined) {
      if (deletedElem.removeInvitation === true && invitation) {
        dispatch(deleteInvitation());
      }
      currentUser.teamId &&
        dispatch(updateUser({ ...currentUser, teamId: "" }));

      dispatch(removeDeletedElem(deletedElem));
    }
  }, [deletedElem]);

  const handleSendVerify = () => {
    dispatch(verifySend());
    navigate("/verify");
  };

  return (
    <div className="w-full h-screen">
      <div className="flex w-full h-full items-center justify-center bg-cover bg-center md:bg-top bg-no-repeat bg-fon-pattern">
        {currentUser?.isVerify ? (
          <div className="flex flex-col mt-32 p-5 justify-center text-center">
            <h1 className="top-1/2  text-xl font-bold my-10 hover:text-white">
              {group !== undefined ? (
                "Название подразделения " + group.teamname
              ) : (
                <p className="top-1/2 text-center text-xl text-white hover:text-blue-600 border rounded-md bg-lime-600 px-5 py-4">
                  Create you team and GO
                </p>
              )}
            </h1>
            <p className="font-bold text-xl hover:text-white my-5">
              {group?.status === "build" &&
                "жди подтверждения участника твоей группы"}
            </p>
            <div className="px-5">
              <button
                className="bg-lime-300 text-black hover:bg-lime-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal({ create: true })}
                disabled={currentUser === null ? true : false}
              >
                Create Team
              </button>
              {showModal.create ? (
                <CreateTeamModal onShowModal={onShowModal} />
              ) : null}
              <button
                className="bg-lime-300 text-black hover:bg-lime-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal({ add: true })}
                disabled={invitation === undefined ? true : false}
              >
                Add to team
              </button>
              {showModal.add ? (
                <AddToTeamModal onShowModal={onShowModal} />
              ) : null}
            </div>
          </div>
        ) : isLoggedIn ?? currentUser !== undefined ? (
          <button
            className="bg-lime-300 text-black hover:bg-lime-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mx-2"
            onClick={handleSendVerify}
          >
            go to verify
          </button>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default Main;
