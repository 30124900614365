import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getJob, getJobError } from "../store/job";
import { toast } from "react-toastify";

const JobBlock = ({ handleShow }) => {
  const navigate = useNavigate();
  const job = useSelector(getJob());
  const error = useSelector(getJobError());
  useEffect(() => {
    toast(error);
  }, [error]);

  return (
    <>
      {job ? (
        <div className="w-full flex flex-col md:mx-10 my-2">
          <div className="flex items-center border-2 py-5 md:px-10">
            <div className="flex flex-col">
              <div className="flex text-center flex-row md:my-5">
                <h1 className="font-bold px-5">Job</h1>
                <p className="px-20">{job.name}</p>
              </div>
              <div className="flex flex-row">
                <h2 className="font-bold px-5">Task</h2>
                <div className="md:px-20">{job.description}</div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end">
            <button
              type="button"
              className="rounded bg-green-400 my-5 hover:bg-green-500 px-3 py-2"
              onClick={() => navigate(-2)}
            >
              загрузить видео
            </button>
          </div>
        </div>
      ) : (
        <div className="w-full flex flex-col md:flex-row justify-items-center">
          <div className="flex justify-center w-full font-bold">
            Check you location and push button ageing
          </div>
          <div className="w-full flex justify-center">
            <button
              type="button"
              className="px-3 md:px-5 py-2 m-5 rounded bg-green-400 mr-5 hover:bg-green-500"
              onClick={handleShow}
            >
              повторить
            </button>
            <button
              type="button"
              className="px-3 md:px-5 py-2 m-5 rounded bg-green-400 mr-5 hover:bg-green-500"
              onClick={() => navigate(-2)}
            >
              вернуться
            </button>
          </div>
        </div>
      )}
    </>
  );
};

JobBlock.propTypes = {
  job: PropTypes.func,
};

export default JobBlock;
