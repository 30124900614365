import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTaskById } from "../store/tasks";
import { getPlay } from "../store/play";
import { createStart, getStartByPlayId } from "../store/start";
import { getCurrentGroup } from "../store/groups";

function Letter({ letter, number, taskId }) {
  const dispatch = useDispatch();
  const group = useSelector(getCurrentGroup());

  const task = useSelector(getTaskById(taskId));
  const play = useSelector(getPlay(group.playId));
  const start = useSelector(getStartByPlayId(group.playId));

  const backgroundColor = (letter) => {
    if (letter === "-") {
      return "w-8 sm:w-12 sm:h-16 h-12 border-solid border-2 border-indigo-600 rounded-sm bg-amber-200 hover:bg-amber-400 mr-2";
    }
    if (typeof letter === "string") {
      return "w-12 h-16 border-solid border-2 border-indigo-600 rounded-sm bg-red-200 hover:bg-red-400 mr-2";
    }
  };

  const handleCheck = () => {
    if (letter === "-") {
      const date = new Date();

      if (!start) {
        dispatch(
          createStart({
            playId: play._id,
            taskId: taskId,
            number: number,
            time: Date.parse(date),
            check: false,
          })
        );
      }
    }
  };

  return (
    <button onClick={handleCheck}>
      <Link
        to={letter === "-" ? "/task/" + taskId : ""}
        state={{
          number: number,
          task: task,
          play: play,
          start: start,
        }}
      >
        <div className={backgroundColor(letter)}>
          <div className="items-center py-2 text-xl">{letter}</div>
        </div>
      </Link>
    </button>
  );
}

Letter.propTypes = {
  letter: PropTypes.string,
  number: PropTypes.number,
  taskId: PropTypes.string,
};

export default Letter;
