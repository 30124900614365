import { createSlice } from "@reduxjs/toolkit";
import skillService from "../service/skill.service";
import isOutdated from "../utils/isOutdated";

const skillsSlice = createSlice({
  name: "skills",
  initialState: {
    entities: null,
    isLoading: true,
    error: null,
    lastFetch: null,
  },
  reducers: {
    skillsRequested: (state) => {
      state.isLoading = true;
    },
    skillsReceived: (state, action) => {
      state.entities = action.payload;
      state.lastFetch = Date.now();
      state.isLoading = false;
    },
    skillsRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

const { reducer: skillsReducer, actions } = skillsSlice;
const { skillsRequested, skillsReceived, skillsRequestFailed } = actions;

export const loadSkillsList = () => async (dispatch, getState) => {
  const { lastFetch } = getState().skills;
  if (isOutdated(lastFetch)) {
    dispatch(skillsRequested());
    try {
      const { content } = await skillService.get();
      dispatch(skillsReceived(content));
    } catch (error) {
      dispatch(skillsRequestFailed(error.message));
    }
  }
};

export const getSkills = () => (state) => state.skills.entities;
export const getSkillsLoadingStatus = () => (state) => state.skills.isLoading;
export const getSkillById = (id) => (state) => {
  if (state.skills.entities) {
    return state.skills.entities.find((p) => p._id === id);
  }
};

export default skillsReducer;
