import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { getIsLoggedIn } from "../../store/users";
import { useSelector } from "react-redux";
import Users from "../../layout/Users";

function ProtectedRoute({ component: Component, children, ...rest }) {
  const isLoggedIn = useSelector(getIsLoggedIn());
  return <>{isLoggedIn ? <Users /> : <Navigate to="/login" />}</>;
}

ProtectedRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ProtectedRoute;
