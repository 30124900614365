export function validator(data, config) {
  const errors = {};
  function validate(validateMethod, data, config) {
    let statusValidate;
    switch (validateMethod) {
      case "isRequired": {
        if (typeof data === "boolean") {
          statusValidate = !data;
        } else {
          statusValidate = data.trim() === "";
        }
        break;
      }
      case "isRequireTown": {
        if (typeof data === "boolean") {
          statusValidate = !data?.town;
        } else {
          statusValidate = data.trim() === "";
        }
        break;
      }
      case "stayOn": {
        statusValidate = data === config.value;
        break;
      }
      case "isEmail": {
        const emailRegExp = /^\S+@\S+\.\S+$/g;
        statusValidate = !emailRegExp.test(data);
        break;
      }
      case "isLetter": {
        const letterRegExp = /[a-zA-Z]+$/g;
        statusValidate = !letterRegExp.test(data);
        break;
      }
      case "isTypeNumber": {
        const typeRegExp = /[0-9]/g;
        statusValidate = !typeRegExp.test(data);
        break;
      }
      case "isCapitalSymbol": {
        const capitalRegExp = /[A-Z]+/g;
        statusValidate = !capitalRegExp.test(data);
        break;
      }
      case "isContainDigitEndLetter": {
        const digitEndLetterRegExp = /^(?![\d+_@.-]+$)[a-zA-Z0-9+_@.-]*$/;
        statusValidate = !digitEndLetterRegExp.test(data);
        break;
      }
      case "isContainDigit": {
        const digitRegExp = /\d+/g;
        statusValidate = !digitRegExp.test(data);
        break;
      }
      case "max": {
        statusValidate = data.length > config.value;
        break;
      }
      case "maxSymbol": {
        statusValidate = data.length > config.value;
        break;
      }
      case "min": {
        statusValidate = data.length < config.value;
        break;
      }
      case "checkName": {
        statusValidate = config.value !== undefined;
        break;
      }
      case "checkMember": {
        statusValidate = config.value === undefined;
        break;
      }
      case "checkGroup": {
        statusValidate = config.value !== "";
        break;
      }
      case "checkInvitation": {
        statusValidate = config.value !== undefined;
        break;
      }
      case "isCurrentUser": {
        statusValidate = data === config.value;
        break;
      }
      case "isDigitOnly": {
        const minRegExp =
          /^(\+)?((\d{1,3}) ?\d|\d)(([ -]?\d)|( ?(\d{2,3}) ?)){9,12}\d$/g;
        statusValidate = !minRegExp.test(data);
        break;
      }
      case "isRepeatUser": {
        statusValidate = data === config.value;
        break;
      }
      case "isLatin": {
        const latin = /^[A-Za-z]+$/g;
        statusValidate = !latin.test(data);
        break;
      }
      case "isChoosen": {
        statusValidate = config.value === data;
        break;
      }
      case "isCheckMission": {
        statusValidate = config.value === data;
        break;
      }
      case "isNotRepeat": {
        statusValidate = config.value === data;
        break;
      }
      default:
        break;
    }
    if (statusValidate) return config.message;
  }
  for (const fieldName in data) {
    for (const validateMethod in config[fieldName]) {
      const error = validate(
        validateMethod,
        data[fieldName],
        config[fieldName][validateMethod]
      );
      if (error && !errors[fieldName]) {
        errors[fieldName] = error;
      }
    }
  }
  return errors;
}
