import httpService from "./http.service";

const skillEndpoint = "skill/";

const skillService = {
  get: async () => {
    const { data } = await httpService.get(skillEndpoint);
    return data;
  },
};
export default skillService;
