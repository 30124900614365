import React, { useEffect, useState } from "react";
import Missions from "../components/Missions";
import { useParams } from "react-router-dom";
import Go from "./Go";
import PaymentFormModal from "../components/common/modal/PaymentFormModal";
import { useDispatch, useSelector } from "react-redux";
import { getMissions } from "../store/missions";
import MissionLoader from "../components/hoc/missionLoader";
import PlayLoader from "../components/hoc/playLoader";
import SelectField from "../components/common/form/SelectField";
import { getTownById, getTowns } from "../store/towns";
import { validator } from "../utils/validator";
import { getCurrentGroup, updateGroup } from "../store/groups";

function ListGo(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const group = useSelector(getCurrentGroup());

  const [modal, setModal] = useState(false);

  const towns = useSelector(getTowns());
  const town = useSelector(getTownById(group && group.town));

  const missions = useSelector(getMissions());
  const [missionId, setMissionId] = useState(null);

  const [errors, setErrors] = useState({});

  const [data, setData] = useState();

  useEffect(() => {
    if (group !== undefined) {
      setData({ town: group.town, missionId: group.missionId });
    }
  }, [group]);

  const paymentForm = (id) => {
    if (modal === false) {
      setModal(true);
      setMissionId(id);
    } else {
      setMissionId(null);
      setModal(false);
    }
  };

  const setSelectTown = (arr) => {
    return arr.map((elem) => ({ value: elem._id, label: elem.name }));
  };

  const handleChange = (target) => {
    setData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const validatorConfig = {
    town: {
      isRequireTown: {
        message: "Не выбран город",
      },
      isNotRepeat: {
        message: "твой город поменяй чтобы изменить",
        value: group && group.town,
      },
    },
    missionId: {
      isCheckMission: {
        message: "Вы находитесь в игре",
        value: "",
      },
    },
  };

  useEffect(() => {
    validate();
  }, [data]);

  const validate = () => {
    const errors = validator(data, validatorConfig);
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const isValid = Object.keys(errors).length === 0;

  const handleSet = () => {
    dispatch(updateGroup({ ...group, town: data.town }));
  };

  return (
    <>
      <MissionLoader>
        <div className="md:container md:mx-auto w-full my-5 text-lg">
          {group?.status === "play" && !group?.missionId ? (
            <>
              <SelectField
                label="Выбери город"
                defaultOption="Choose..."
                options={setSelectTown(towns)}
                name="town"
                onChange={handleChange}
                value={data?.town}
                error={errors.town}
              />
              {!isValid && (
                <button
                  onClick={handleSet}
                  className="w-40 h-10 m-5 rounded bg-green-400 mr-5 hover:bg-green-500"
                  disabled={!isValid ? true : false}
                >
                  изменить
                </button>
              )}
            </>
          ) : (
            <div className="flex flex-row gap-4 mx-3 border-b text-gray-400">
              <h2>город</h2>
              <p>{town && town.name}</p>
            </div>
          )}
          {group && group.town && !isValid ? (
            !params.id ? (
              <div className="mt-10">
                <Missions list={missions} paymentForm={paymentForm} />
                {modal ? (
                  <PaymentFormModal
                    paymentForm={paymentForm}
                    missionId={missionId}
                  />
                ) : null}
              </div>
            ) : (
              <PlayLoader>
                <Go />
              </PlayLoader>
            )
          ) : (
            <div>Выберите город</div>
          )}
        </div>
      </MissionLoader>
    </>
  );
}

ListGo.propTypes = {};

export default ListGo;
