import localStorageService from "./http.localStorage";
import httpService from "./http.service";

const invitationEndpoint = "invitation/";

const invitationService = {
  create: async (payload) => {
    const { data } = await httpService.post(invitationEndpoint, payload);
    return data;
  },
  getInvitations: async () => {
    const { data } = await httpService.get(invitationEndpoint);
    return data;
  },
  // проверить необходимость get
  get: async (email, type) => {
    const { data } = await httpService.get(invitationEndpoint, {
      params: {
        orderBy: `${type}`,
        equalTo: `${email}`,
      },
    });
    return data;
  },
  getByEmail: async (email) => {
    const { data } = await httpService.get(invitationEndpoint, {
      params: {
        equalTo: `${email}`,
      },
    });
    return data;
  },
  delete: async () => {
    const { data } = await httpService.delete(
      invitationEndpoint + localStorageService.getUserId()
    );
    return data;
  },
};

export default invitationService;
