import { createSlice } from "@reduxjs/toolkit";
import isOutdated from "../utils/isOutdated";
import townService from "../service/town.service";

const townsSlice = createSlice({
  name: "towns",
  initialState: {
    entities: [],
    isLoading: true,
    error: null,
    lastFetch: null,
  },
  reducers: {
    townsRequested: (state) => {
      state.isLoading = true;
    },
    townsReceived: (state, action) => {
      state.entities = action.payload;
      state.lastFetch = Date.now();
      state.isLoading = false;
    },
    townsRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

const { reducer: townsReducer, actions } = townsSlice;
const { townsRequested, townsReceived, townsRequestFailed } = actions;

export const loadTownsList = () => async (dispatch, getState) => {
  const { lastFetch } = getState().towns;
  if (isOutdated(lastFetch)) {
    dispatch(townsRequested());
    try {
      const { content } = await townService.get();
      dispatch(townsReceived(content));
    } catch (error) {
      dispatch(townsRequestFailed(error.message));
    }
  }
};

export const getTowns = () => (state) => state.towns.entities;
export const getTownsLoadingStatus = () => (state) => state.towns.isLoading;
export const getTownById = (id) => (state) => {
  if (state.towns.entities) {
    return state.towns.entities.find((p) => p._id === id);
  }
};

export default townsReducer;
