import { createSlice } from "@reduxjs/toolkit";
import specialtyService from "../service/specialty.service";
import isOutdated from "../utils/isOutdated";

const specialtiesSlice = createSlice({
  name: "specialties",
  initialState: {
    entities: null,
    isLoading: true,
    error: null,
    lastFetch: null,
  },
  reducers: {
    specialtiesRequested: (state) => {
      state.isLoading = true;
    },
    specialtiesReceived: (state, action) => {
      state.entities = action.payload;
      state.lastFetch = Date.now();
      state.isLoading = false;
    },
    specialtiesRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

const { reducer: specialtiesReducer, actions } = specialtiesSlice;
const { specialtiesRequested, specialtiesReceived, specialtiesRequestFailed } =
  actions;

export const loadSpecialtiesList = () => async (dispatch, getState) => {
  const { lastFetch } = getState().specialties;
  if (isOutdated(lastFetch)) {
    dispatch(specialtiesRequested());
    try {
      const { content } = await specialtyService.get();
      dispatch(specialtiesReceived(content));
    } catch (error) {
      dispatch(specialtiesRequestFailed(error.message));
    }
  }
};

export const getSpecialties = () => (state) => state.specialties.entities;
export const getSpecialtiesLoadingStatus = () => (state) =>
  state.specialties.isLoading;
export const getSpecialtyById = (id) => (state) => {
  if (state.specialties.entities) {
    return state.specialties.entities.find((p) => p._id === id);
  }
};

export default specialtiesReducer;
