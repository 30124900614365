import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserData, updateUser } from "../../../store/users";
import {
  deleteInvitation,
  getCurrentUserInvitation,
} from "../../../store/invitations";
import { deleteGroup, getGroupById, updateGroup } from "../../../store/groups";
import Invitation from "../../../ui/invitation/Invitation";
import { createDeletedElem } from "../../../store/delete";
import localStorageService from "../../../service/http.localStorage";

const AddToTeamModal = ({ onShowModal }) => {
  const currentUser = useSelector(getCurrentUserData());
  const invitation = useSelector(getCurrentUserInvitation());
  const group = useSelector(getGroupById(invitation.groupId));
  const dispatch = useDispatch();

  const findGroupAndUpdate = async (invitation) => {
    const newMembers = group.members.map((elem) => {
      return elem.member !== invitation.to ? elem : { ...elem, accept: true };
    });
    let payload = {
      ...group,
      members: newMembers,
    };
    dispatch(updateGroup(payload));
  };

  const handleAccept = () => {
    if (invitation !== null && currentUser) {
      dispatch(updateUser({ ...currentUser, teamId: invitation.groupId }));
      findGroupAndUpdate(invitation);
      const local = localStorageService.getGroupId();
      !local && localStorage.setItem("group-local-id", invitation.groupId);
      dispatch(deleteInvitation());

      onShowModal();
    }
  };

  const handleReject = () => {
    if (group.members[1].member === invitation.to) {
      dispatch(
        createDeletedElem({
          userId: group.members[2].member,
          removeInvitation: true,
        })
      );
      dispatch(
        createDeletedElem({
          userId: group.members[0].member,
          removeInvitation: false,
        })
      );
    } else if (group.members[2].member === invitation.to) {
      dispatch(
        createDeletedElem({
          userId: group.members[1].member,
          removeInvitation: true,
        })
      );
      dispatch(
        createDeletedElem({
          userId: group.members[0].member,
          removeInvitation: false,
        })
      );
    }
    dispatch(deleteGroup({ groupId: invitation.groupId }));
    dispatch(deleteInvitation());
    onShowModal();
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Add to team</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => onShowModal()}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <div className="relative p-6 flex-auto">
              <p className="my-4 text-slate-500 text-lg leading-relaxed">
                Комманда включает до 3-х человек. Выполнение задания может
                возлагаться на одного человека, который получает за выполнение
                задания Skills указанный в задании. Если вы получили
                приглашения, то вы увидите их в списке ниже. Примите приглашение
                от одной команды и вы будете добавлены в комманду.
              </p>
            </div>
            {invitation !== null ? (
              <div>
                <div className="flex justify-center p-4">
                  <Invitation />
                </div>

                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => onShowModal()}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white hover:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleAccept}
                  >
                    Accept
                  </button>
                  <button
                    className="bg-emerald-500 text-white hover:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleReject}
                  >
                    Reject
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="flex justify-center p-4">
                  {group === null ? (
                    <p>У Вас нет приглашений</p>
                  ) : (
                    <p>Вы состоите в группе</p>
                  )}
                </div>

                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => onShowModal()}
                  >
                    Close
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddToTeamModal;
