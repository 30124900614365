import httpService from "./http.service";

const specialtyEndpoint = "specialties/";

const specialtyService = {
  get: async () => {
    const { data } = await httpService.get(specialtyEndpoint);
    return data;
  },
};
export default specialtyService;
