import { createSlice } from "@reduxjs/toolkit";
import jobService from "../service/job.service";

const jobSlice = createSlice({
  name: "job",
  initialState: {
    entities: null,
    isLoading: false,
    jobLoaded: false,
    error: null,
  },
  reducers: {
    jobRequested: (state) => {
      state.isLoading = true;
    },
    jobReceived: (state, action) => {
      if (action.payload) {
        state.entities = action.payload;
      }
      state.isLoading = false;
      state.jobLoaded = true;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    removeJob: (state) => {
      state.entities = null;
      state.isLoading = false;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetState: (state) => {
      state.entities = null;
      state.isLoading = false;
      state.error = null;
      state.jobLoaded = false;
    },
  },
});

const { reducer: jobReducer, actions } = jobSlice;

export const {
  jobRequested,
  jobReceived,
  setLoading,
  setError,
  resetState,
  removeJob,
} = actions;

export const getJobById = (taskId, jobId) => async (dispatch, getState) => {
  dispatch(jobRequested());
  const { locations } = getState();
  dispatch(setError(null));

  try {
    const { content } = await jobService.get({
      taskId,
      jobId,
      location: locations.userLocation,
    });

    dispatch(jobReceived(content));
  } catch (error) {
    dispatch(
      setError(
        error?.response.data.message
          ? error?.response.data.message
          : error?.message
      )
    );
  }
};
export const clearJob = () => async (dispatch) => {
  dispatch(jobRequested());
  try {
    dispatch(removeJob());
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const getJobStatus = () => (state) => state.job.jobLoaded;
export const getJob = () => (state) => state.job.entities;

export const getJobError = () => (state) => state.job.error;

export default jobReducer;
