import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getAuthErrors,
  getCurrentUserData,
  getIsLoggedIn,
} from "../store/users";
import NavProfile from "./NavProfile";
import { getGroupById } from "../store/groups";
import { toast } from "react-toastify";
import localStorageService from "../service/http.localStorage";

function NavBar(props) {
  const isLoggedIn = useSelector(getIsLoggedIn());
  const navigate = useNavigate();
  const currentUser = useSelector(getCurrentUserData());
  const group = useSelector(getGroupById(currentUser && currentUser.teamId));
  const error = useSelector(getAuthErrors());
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (currentUser === undefined) {
      localStorageService.removeAuthData();
    }
  }, [currentUser]);

  useEffect(() => {
    if (error) {
      toast(error);
      navigate("/");
    }
  }, [error]);

  const handleOpen = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <div className="md:container md:mx-auto">
      <div className="hidden sm:flex w-full flex-wrap justify-between">
        <nav className="relative flex flex-wrap w-full items-center justify-between px-2 py-3 bg-pink-500 mb-3">
          <ul className="flex justify-between mr-10">
            <li>
              <Link to="/">
                <button
                  className="w-20 md:w-40 h-10 rounded bg-lime-500 mr-5 hover:bg-lime-300"
                  disabled={currentUser?.isVerify ? false : true}
                >
                  Main
                </button>
              </Link>
            </li>
            {isLoggedIn && currentUser?.isVerify && (
              <>
                <li>
                  <Link to="list-go">
                    <button
                      className="w-20 md:w-40 h-10 rounded bg-lime-500 mr-5 hover:bg-lime-300"
                      disabled={group && group.status === "play" ? false : true}
                    >
                      Go
                    </button>
                  </Link>
                </li>
                <li>
                  <Link to="user">
                    <button
                      className="w-20 md:w-40 h-10 rounded bg-lime-500 mr-5 hover:bg-lime-300"
                      disabled={currentUser?.isVerify ? false : true}
                    >
                      Players
                    </button>
                  </Link>
                </li>
              </>
            )}
          </ul>
          <div className="d-flex">
            {isLoggedIn ? (
              <NavProfile />
            ) : (
              <Link to="login">
                <button className="px-5 h-10 rounded bg-lime-500 mr-5 hover:bg-lime-300">
                  Login
                </button>
              </Link>
            )}
          </div>
        </nav>
      </div>
      <div
        className="flex justify-end sm:hidden h-10 bg-pink-500 pb-2 pr-10 rounded-md font-extrabold pt-4"
        onClick={handleOpen}
      >
        <p className="hover:bg-pink-200 rounded-sm py-1 px-3">MENU</p>
        <div className="flex justify-end">
          {isLoggedIn ? (
            <NavProfile />
          ) : (
            <Link to="login">
              <button className="px-5 h-10 rounded bg-lime-500 hover:bg-lime-300">
                Login
              </button>
            </Link>
          )}
        </div>
      </div>
      {open && (
        <div className="absolute w-72 bg-gray-500 py-5 -translate-y-8">
          <ul className="flex flex-col text-white font-bold gap-2">
            <Link to="/">
              <button
                className="w-full x-10 py-3 hover:bg-lime-300 hover:text-black"
                onClick={handleOpen}
                disabled={currentUser?.isVerify ? false : true}
              >
                Main
              </button>
            </Link>
            {isLoggedIn && currentUser?.isVerify && (
              <>
                <Link to="/list-go">
                  <button
                    className="w-full px-10 py-3 hover:bg-lime-300 hover:text-black"
                    onClick={handleOpen}
                    disabled={group && group.status === "play" ? false : true}
                  >
                    GO
                  </button>
                </Link>
                <Link to="user">
                  <button
                    className="w-full px-10 py-3 hover:bg-lime-300 hover:text-black"
                    onClick={handleOpen}
                    disabled={currentUser?.isVerify ? false : true}
                  >
                    Players
                  </button>
                </Link>
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

NavBar.propTypes = {};

export default NavBar;
