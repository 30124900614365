import React, { useState } from "react";
import PlayLoader from "./hoc/playLoader";
import { useSelector } from "react-redux";
import { getPlaysList } from "../store/play";
import PlayTable from "../ui/playTable";
import MissionLoader from "./hoc/missionLoader";

// onSort, selectedSort, columns, data, children;
const Results = () => {
  const plays = useSelector(getPlaysList());
  const [sortBy, setSortBy] = useState({ path: "name", order: "asc" });

  const handleSort = (item) => {
    setSortBy(item);
  };

  return (
    <PlayLoader>
      <MissionLoader>
        <div className="flex-row my-10 border-solid border-2 border-indigo-600">
          {plays && (
            <PlayTable
              plays={plays}
              onSort={handleSort}
              selectedSort={sortBy}
            />
          )}
        </div>
      </MissionLoader>
    </PlayLoader>
  );
};

export default Results;
