import { createSlice } from "@reduxjs/toolkit";
import levelService from "../service/level.service";
import isOutdated from "../utils/isOutdated";

const levelsSlice = createSlice({
  name: "levels",
  initialState: {
    entities: null,
    isLoading: false,
    error: null,
    lastFetch: null,
  },
  reducers: {
    levelsRequested: (state) => {
      state.isLoading = true;
    },
    levelsReceived: (state, action) => {
      state.entities = action.payload;
      state.lastFetch = Date.now();
      state.isLoading = false;
    },
    levelsRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

const { reducer: levelsReducer, actions } = levelsSlice;
const { levelsRequested, levelsReceived, levelsRequestFailed } = actions;

export const loadLevelsList = () => async (dispatch, getState) => {
  const { lastFetch } = getState().levels;
  if (isOutdated(lastFetch)) {
    dispatch(levelsRequested());
    try {
      const { content } = await levelService.get();
      dispatch(levelsReceived(content));
    } catch (error) {
      dispatch(levelsRequestFailed(error.message));
    }
  }
};

export const getLevels = () => (state) => state.levels.entities;
export const getLevelsLoadingStatus = () => (state) => state.levels.isLoading;
export const getLevelById = (id) => (state) => {
  if (state.levels.entities) {
    return state.levels.entities.find((p) => p._id === id);
  }
};
export const getLevelOne = () => (state) => {
  if (state.levels.entities) {
    return state.levels.entities[2];
  }
};

export default levelsReducer;
