import React from "react";
import PropTypes from "prop-types";

const SearchStatus = ({ length }) => {
  const renderPhrase = (number) => {
    const lastOne = Number(number.toString().slice(-1));
    if (number > 4 && number < 15) {
      return "человек зарегистрированы в игре";
    }
    if (lastOne === 1) return "человек зарегистрированны в игре";
    if ([2, 3, 4].indexOf(lastOne) >= 0)
      return "человека зарегистрированны в игре";
    return "человек зарегистрированны в игре";
  };
  return (
    <span className="py-5 px-5 bg-lime-50 rounded-md font-mono font-extrabold">
      {length > 0
        ? `${length + " " + renderPhrase(length)}   `
        : "Игроков нету"}
    </span>
  );
};

SearchStatus.propTypes = {
  length: PropTypes.number,
};

export default SearchStatus;
