import React from "react";

function ConfirmationForm({ open, handleCancel, handleConfirm }) {
  if (!open) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      {/* <div className="absolute inset-0 bg-black opacity-10 shadow-xl"></div> */}
      <div className="bg-white rounded-lg p-8 border-spacing-2 ring-2 ring-slate-400">
        <h2 className="text-xl font-bold mb-4">Подтверждение</h2>
        <p className="text-gray-700 mb-4">Вы уверены, что хотите продолжить?</p>
        <div className="flex justify-end">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
            onClick={handleConfirm}
          >
            Подтвердить
          </button>
          <button
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handleCancel}
          >
            Отмена
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationForm;
