import localStorageService from "./http.localStorage";
import httpService from "./http.service";

const playEndpoint = "play/";

const playService = {
  get: async (teamId) => {
    const { data } = await httpService.get(
      playEndpoint + localStorageService.getUserId(),
      {
        params: {
          equalTo: `${teamId}`,
        },
      }
    );
    return data;
  },
  update: async (payload) => {
    const { data } = await httpService.patch(
      playEndpoint + localStorageService.getUserId(),
      payload
    );
    return data;
  },
  // checkAndUpdate: async (payload) => {
  //   const { data } = await httpService.patch(
  //     playEndpoint + localStorageService.getUserId(),
  //     payload,
  //     {
  //       params: {
  //         equalTo: `${payload._id}`,
  //       },
  //     }
  //   );
  //   return data;
  // },
};
export default playService;
