import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getTaskById } from "../store/tasks";
import { getTime } from "../utils/timeConverte";

function Complete({ start, play }) {
  const task = useSelector(getTaskById(start.taskId));
  return (
    <div className="container">
      <div className="flex justify-center">
        <div className="flex flex-col justify-center">
          <div>
            <h1>Задание не завершено загрузите видео, завершите задание</h1>
          </div>
          <div className="flex flex-row justify-center">
            До завершения<p className="px-2">{getTime(start.time)}</p> часов
          </div>

          <Link
            to={"/task/" + start.taskId}
            state={{
              number: start.number,
              task: task,
              play: play,
              start: start,
            }}
            className="flex justify-center"
          >
            <button
              type="button"
              className="w-40 h-10 m-5 rounded bg-green-400 mr-5 hover:bg-green-500"
            >
              Go
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

Complete.propTypes = {
  start: PropTypes.object,
  play: PropTypes.object,
};

export default Complete;
