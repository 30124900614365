import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Verify } from "../store/users";
import { useNavigate } from "react-router-dom";

const VerifyComponent = (props) => {
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSend = () => {
    const redirect = navigate("/");
    dispatch(Verify({ code: code, redirect }));
  };
  return (
    <div className="container w-full h-screen">
      <div className="flex justify-center my-24 ">
        <div>
          <p className="text-lg py-3">Введи код отправленый на почту</p>
          <div className="flex flex-row justify-between">
            <input
              className="bg-slate-100 py-2 number-input w-20"
              name="code"
              type="number"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
            />
            <button
              className="bg-lime-300 text-black hover:bg-lime-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mx-2"
              onClick={handleSend}
            >
              verify email
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyComponent;
