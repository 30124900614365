import httpService from "./http.service";

const townEndpoint = "town/";

const townService = {
  get: async () => {
    const { data } = await httpService.get(townEndpoint);
    return data;
  },
};
export default townService;
