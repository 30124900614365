import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Table from "../components/common/table";
import Level from "./Level";
import Skills from "../ui/skills/index.js";
import UserGroupName from "../components/common/UserGroupName";

const UserTable = ({ users, onSort, selectedSort, ...rest }) => {
  const columns = {
    nickname: {
      path: "nickname",
      name: "nickname",
      component: (user) => (
        <div className="flex justify-center">
          <Link
            to={user._id}
            state={{ user: user }}
            className="hover:text-blue-400 font-bold"
          >
            {user.nickname}
          </Link>
        </div>
      ),
    },
    skills: {
      name: "skills",
      component: (user) => <Skills skills={user.skill} />,
    },
    level: {
      name: "уровень",
      component: (user) => <Level id={user.level} />,
    },
    group: {
      name: "group name",
      component: (user) => <UserGroupName id={user.teamId} />,
    },
  };
  return (
    <Table
      selectedSort={selectedSort}
      onSort={onSort}
      columns={columns}
      data={users}
    />
  );
};

UserTable.propTypes = {
  users: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
  selectedSort: PropTypes.object.isRequired,
};

export default UserTable;
