import React from "react";
import PropTypes from "prop-types";

function Button({ name, handleClick, className }) {
  return (
    <button
      className={
        "inline-flex justify-center rounded-md border border-indigo-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-indigo-200" +
        className
      }
      onClick={handleClick}
    >
      {name}
    </button>
  );
}

Button.propTypes = {
  name: PropTypes.string,
  handleClick: PropTypes.func,
  className: PropTypes.string,
};

export default Button;
