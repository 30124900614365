import { createSlice } from "@reduxjs/toolkit";
import isOutdated from "../utils/isOutdated";
import countryService from "../service/country.service";

const countriesSlice = createSlice({
  name: "countries",
  initialState: {
    entities: null,
    isLoading: true,
    error: null,
    lastFetch: null,
  },
  reducers: {
    CountriesRequested: (state) => {
      state.isLoading = true;
    },
    CountriesReceived: (state, action) => {
      state.entities = action.payload;
      state.lastFetch = Date.now();
      state.isLoading = false;
    },
    CountriesRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

const { reducer: countriesReducer, actions } = countriesSlice;
const { CountriesRequested, CountriesReceived, CountriesRequestFailed } =
  actions;

export const loadCountriesList = () => async (dispatch, getState) => {
  const { lastFetch } = getState().countries;
  if (isOutdated(lastFetch)) {
    dispatch(CountriesRequested());
    try {
      const { content } = await countryService.get();
      dispatch(CountriesReceived(content));
    } catch (error) {
      dispatch(CountriesRequestFailed(error.message));
    }
  }
};

export const getCountries = () => (state) => state.countries.entities;
export const getCountriesLoadingStatus = () => (state) =>
  state.countries.isLoading;
export const getCountryById = (id) => (state) => {
  if (state.countries.entities) {
    return state.countries.entities.find((p) => p._id === id);
  }
};

export default countriesReducer;
