import httpService from "./http.service";

const jobEndpoint = "jobs/";

const jobService = {
  get: async (payload) => {
    const { data } = await httpService.get(jobEndpoint + payload.jobId, {
      params: {
        taskId: `${payload.taskId}`,
        latitude: `${payload.location.latitude}`,
        longitude: `${payload.location.longitude}`,
      },
    });
    return data;
  },
};

export default jobService;
