import React, { useEffect, useState } from "react";
import { validator } from "../../utils/validator";
import { useSelector } from "react-redux";
import { getCurrentUserData } from "../../store/users";
import { useNavigate } from "react-router-dom";
import TextField from "../../components/common/form/TextField";
import SelectField from "../../components/common/form/SelectField";
import RadioField from "../../components/common/form/RadioField";
import CheckBoxField from "../../components/common/form/CheckBoxField";
import { getSpecialties } from "../../store/specialties";

const EditUserPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const currentUser = useSelector(getCurrentUserData());
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const specialties = useSelector(getSpecialties());

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) return;
    // dispatch(
    //   updateUser({
    //     ...data,
    //     qualities: data.qualities.map((q) => q.value),
    //   })
    // );
  };

  useEffect(() => {
    if (currentUser && !data) {
      setData(currentUser);
    }
  }, [currentUser, data]);

  useEffect(() => {
    if (data && isLoading) {
      setIsLoading(false);
    }
  }, [data]);

  const validatorConfig = {
    email: {
      isRequired: {
        message: "Электронная почта обязательна для заполнения",
      },
      isEmail: {
        message: "Email введен некорректно",
      },
    },
    name: {
      isRequired: {
        message: "Введите ваше имя",
      },
    },
  };
  // useEffect(() => validate(), [data]);
  const handleChange = (target) => {
    setData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const setSelectSpecialties = (arr) => {
    return arr.map((elem) => ({ value: elem._id, label: elem.name }));
  };

  const handleBack = () => {
    navigate(-1);
  };

  const validate = () => {
    const errors = validator(data, validatorConfig);
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const isValid = Object.keys(errors).length === 0;
  return (
    <div className="container mt-5">
      <div className="flex justify-center">
        <div className="col-md-6 offset-md-3 shadow p-4">
          {!isLoading ? (
            <form onSubmit={handleSubmit}>
              <TextField
                label="Электронная почта"
                name="email"
                type="text"
                value={data.email}
                onChange={handleChange}
                error={errors.email}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="go888@gmail.com"
              />
              <TextField
                label="Пароль"
                type="password"
                name="password"
                value={data.password}
                onChange={handleChange}
                error={errors.password}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <TextField
                label="Nickname"
                name="nickname"
                value={data.nickname}
                onChange={handleChange}
                error={errors.nickname}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Tiger"
              />
              <TextField
                label="Mobile phone number"
                name="phoneNumber"
                value={String(data.phoneNumber)}
                onChange={handleChange}
                error={errors.phoneNumber}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="+77758889966"
              />
              <SelectField
                label="Выбери свою специальность"
                defaultOption="Choose..."
                options={setSelectSpecialties(specialties)}
                name="specialty"
                onChange={handleChange}
                value={data.specialty}
                error={errors.specialty}
              />
              <RadioField
                options={[
                  { name: "Male", value: "male" },
                  { name: "Female", value: "female" },
                  // { name: "Other", value: "other" },
                ]}
                value={data.sex}
                name="sex"
                onChange={handleChange}
                label="Выберите ваш пол"
              />
              <CheckBoxField
                value={data.license}
                onChange={handleChange}
                name="license"
                error={errors.license}
              >
                Подтвердить <a>соглашение</a>
              </CheckBoxField>
              <div className="flex justify-between mx-5 py-5">
                <input
                  type="submit"
                  value="Submit"
                  className="w-28 focus:outline-none text-black bg-lime-400 hover:bg-lime-500 focus:ring-4 focus:ring-lime-300 rounded-lg text-sm px-6 py-3 dark:bg-lime-500 dark:hover:bg-lime-500 dark:focus:ring-lime-800 font-bold uppercase"
                  disabled={!isValid}
                />
                <button
                  className="w-28 bg-lime-400 text-black hover:bg-lime-500 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  onClick={handleBack}
                  type="submit"
                >
                  back
                </button>
              </div>
            </form>
          ) : (
            "Loading..."
          )}
        </div>
      </div>
    </div>
  );
};

export default EditUserPage;
