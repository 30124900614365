import localStorageService from "./http.localStorage";
import httpService from "./http.service";

const deleteEndpoint = "delete/";

const deleteService = {
  get: async () => {
    const { data } = await httpService.get(deleteEndpoint);
    return data;
  },
  getOne: async () => {
    const { data } = await httpService.get(
      deleteEndpoint + localStorageService.getUserId()
    );
    return data;
  },
  create: async (payload) => {
    const { data } = await httpService.post(
      deleteEndpoint + localStorageService.getUserId(),
      payload
    );
    return data;
  },
  deleteElem: async () => {
    const { data } = await httpService.delete(
      deleteEndpoint + localStorageService.getUserId()
    );
    return data;
  },
};

export default deleteService;
