import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logOut } from "../store/users";
import { logOutGroup } from "../store/groups";
import { logOutInvitation } from "../store/invitations";
import { logOutDelete } from "../store/delete";
import { logOutTask } from "../store/tasks";
import { logOutMission } from "../store/missions";
import { logOutPlay } from "../store/play";
import { logOutPayment } from "../store/payment";
import { logOutFile } from "../store/fileUpload";
import { loggedOutStart } from "../store/start";

const LogOut = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loggedOutStart());
    dispatch(logOutFile());
    dispatch(logOutPayment());
    dispatch(logOutTask());
    dispatch(logOutPlay());
    dispatch(logOutMission());
    dispatch(logOutInvitation());
    dispatch(logOutGroup());
    dispatch(logOutDelete());
    dispatch(logOut());
  }, [dispatch]);
  return (
    <div className="w-full h-screen">
      <p>Loading...</p>
    </div>
  );
};

export default LogOut;
