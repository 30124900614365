import { createAction, createSlice } from "@reduxjs/toolkit";
import playService from "../service/play.service";
import localStorageService from "../service/http.localStorage";

const playsSlice = createSlice({
  name: "plays",
  initialState: {
    entities: null,
    isLoading: false,
    playLoader: false,
    error: null,
  },
  reducers: {
    playsRequested: (state) => {
      state.isLoading = true;
    },
    playsReceived: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
      state.playLoader = true;
    },
    playsUpdate: (state, action) => {
      state.entities[
        state.entities.findIndex((u) => u._id === action.payload._id)
      ] = action.payload;
    },
    playsRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    playLogOut: (state, action) => {
      state.entities = null;
      state.isLoading = true;
      state.playLoader = false;
    },
  },
});

const { reducer: playsReducer, actions } = playsSlice;
const {
  playsRequested,
  playsReceived,
  playsRequestFailed,
  playsUpdate,
  playLogOut,
} = actions;

const playUpdateRequested = createAction("plays/playUpdateRequested");
const playUpdateFailed = createAction("plays/playUpdateFailed");

export const loadPlay = () => async (dispatch) => {
  dispatch(playsRequested());
  const groupId = localStorageService.getGroupId();
  try {
    if (groupId) {
      const { content } = await playService.get(groupId);
      dispatch(playsReceived(content));
    }
  } catch (error) {
    dispatch(playsRequestFailed(error.message));
  }
};

export const updatePlay = (payload) => async (dispatch) => {
  dispatch(playUpdateRequested());
  try {
    const { content } = await playService.update(payload);
    dispatch(playsUpdate(content));
  } catch (error) {
    dispatch(playUpdateFailed());
  }
};

export const logOutPlay = () => (dispatch) => {
  dispatch(playLogOut());
};

export const getPlay = (playId) => (state) => {
  if (state.play.entities) {
    return state.play.entities.find((u) => u._id === playId);
  }
};

export const getPlaysList = () => (state) => state.play.entities;

export const getPlayStatus = () => (state) => state.play.playLoader;

export default playsReducer;
