import localStorageService from "./http.localStorage";
import httpService from "./http.service";

const startEndpoint = "start/";

const startService = {
  get: async () => {
    const { data } = await httpService.get(startEndpoint);
    return data;
  },
  create: async (payload) => {
    const { data } = await httpService.post(
      startEndpoint + localStorageService.getUserId(),
      payload
    );
    return data;
  },
  update: async (payload) => {
    const { data } = await httpService.patch(
      startEndpoint + localStorageService.getUserId(),
      payload
    );
    return data;
  },
  delete: async (payload) => {
    const { data } = await httpService.delete(
      startEndpoint + localStorageService.getUserId(),
      {
        params: {
          startId: `${payload}`,
        },
      }
    );
    return data;
  },
};
export default startService;
