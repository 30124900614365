import { combineReducers, configureStore } from "@reduxjs/toolkit";
import usersReducer from "./users";
import specialtiesReducer from "./specialties";
import levelsReducer from "./levels";
import invitationsReducer from "./invitations";
import missionsReducer from "./missions";
import groupsReducer from "./groups";
import deletesReducer from "./delete";
import paymentsReducer from "./payment";
import playsReducer from "./play";
import tasksReducer from "./tasks";
import fileUploadReducer from "./fileUpload";
import startReducer from "./start";
import jobReducer from "./job";
import locationsReducer from "./location";
import countriesReducer from "./countries";
import townsReducer from "./towns";
import skillsReducer from "./skills";

const rootReducer = combineReducers({
  levels: levelsReducer,
  specialties: specialtiesReducer,
  skills: skillsReducer,
  invitation: invitationsReducer,
  users: usersReducer,
  groups: groupsReducer,
  missions: missionsReducer,
  deletes: deletesReducer,
  payment: paymentsReducer,
  play: playsReducer,
  tasks: tasksReducer,
  fileUpload: fileUploadReducer,
  start: startReducer,
  job: jobReducer,
  locations: locationsReducer,
  countries: countriesReducer,
  towns: townsReducer,
});

export function createStore() {
  return configureStore({
    reducer: rootReducer,
  });
}
