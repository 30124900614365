import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createPayment,
  getPaymentSumByMissionId,
} from "../../../store/payment";
import { getCurrentUserData } from "../../../store/users";
import { getGroupById, updateGroup } from "../../../store/groups";

function PaymentFormModal({ paymentForm, missionId }) {
  const dispatch = useDispatch();

  const missionPayment = useSelector(getPaymentSumByMissionId(missionId));
  const currentUser = useSelector(getCurrentUserData());
  const group = useSelector(getGroupById(currentUser.teamId));

  const [inputValue, setInputValue] = useState({
    payer: currentUser._id,
    sum: "15000",
    missionId: missionId,
    teamId: currentUser.teamId,
  });

  // const handleChange = ({ target }) => {
  //   setInputValue((prevState) => ({
  //     ...prevState,
  //     [target.name]: target.value,
  //   }));
  // };

  const onPayment = async () => {
    if (
      (missionPayment < 15000 && group.missionId === null) ||
      group.missionId === ""
    ) {
      dispatch(createPayment(inputValue));
      if (missionPayment + Number(inputValue.sum) > 14999) {
        dispatch(updateGroup({ ...group, missionId }));
      }
    }
    setInputValue({
      payer: currentUser._id,
      sum: "0",
      missionId: missionId,
      teamId: currentUser.teamId,
    });
    paymentForm(null);
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Payment window</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={paymentForm}
              >
                <span className="bg-transparent text-black bg-yellow-100 opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <div className="relative p-6 flex-auto">
              <p className="my-4 text-slate-500 text-lg leading-relaxed">
                Оплатите 15000 тенге за команду или частичную сумму за игрока
                после полной оплаты миссия будет доступна к выполнению
              </p>
            </div>
            <div className="flex px-6">
              <p>Оплачено:</p>
              <p className="text-slate-500 pl-2">
                {missionPayment === undefined ? "0" : missionPayment}
              </p>
            </div>

            <form className="w-full max-w-sm">
              <div className="md:flex md:items-center mb-6 m-8">
                <label
                  className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-6 md:w-1/3"
                  htmlFor="inline-full-name"
                >
                  сумма к оплате
                </label>
                <div className="md:w-2/3">
                  <input
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    name="sum"
                    type="number"
                    value={
                      inputValue.sum <= 15000 && inputValue.sum > 0
                        ? inputValue.sum
                        : 0
                    }
                    // onChange={handleChange}
                    placeholder="введите сумму"
                  />
                </div>
              </div>
            </form>
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={paymentForm}
              >
                Close
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={onPayment}
                disabled={Number(inputValue.sum) > 0 ? false : true}
              >
                Send payment
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default PaymentFormModal;
