import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getPlayStatus, loadPlay } from "../../store/play";

const PlayLoader = ({ children }) => {
  const dispatch = useDispatch();
  const playLoader = useSelector(getPlayStatus());

  useEffect(() => {
    dispatch(loadPlay());
  }, []);

  if (!playLoader)
    return (
      <div className="w-full h-full">
        <p className="flex justify-center my-5">Loading play...</p>
      </div>
    );
  return children;
};

PlayLoader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default PlayLoader;
