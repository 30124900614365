import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import configFile from "./config.json";
import NavBar from "./components/NavBar";
import ListGo from "./page/ListGo";
import Main from "./layout/Main";
import Task from "./components/Task";
import Go from "./page/Go";
import Login from "./layout/Login";
import { ToastContainer } from "react-toastify";
import LogOut from "./layout/Logout";
import AppLoader from "./components/hoc/appLoader";
import ProtectedRoute from "./components/common/ProtectedRoute";
import UserPage from "./page/userPage";
import EditUserPage from "./page/userPage/editUserPage";
import Job from "./components/Job";
import Footer from "./components/Footer";
import VerifyComponent from "./components/VerifyComponent";

function App() {
  return (
    <div className="container md:mx-auto w-full">
      <HashRouter>
        <GoogleReCaptchaProvider
          reCaptchaKey={`${configFile.REACT_APP_CAPTCHA_SITE}`}
          scriptProps={{
            async: false, // optional, default to false,
            defer: false, // optional, default to false
            appendTo: "head", // optional, default to "head", can be "head" or "body",
            nonce: undefined, // optional, default undefined
          }}
        >
          <AppLoader>
            <NavBar />
            <Routes>
              <Route path="/" element={<App />} />
              <Route path="/login" element={<Login />}>
                <Route path=":type?" element={<Go />} />
              </Route>
              <Route path="verify" element={<VerifyComponent />} />
              <Route path="logout" element={<LogOut />} />
              <Route index element={<Main />} />
              <Route path="list-go" element={<ListGo />}>
                <Route path=":id" element={<Go />} />
              </Route>
              <Route path="task/:id" element={<Task />} />
              <Route path="job/:missionId" element={<Job />} />
              <Route path="user" element={<ProtectedRoute />}>
                <Route path=":userId" element={<UserPage />}>
                  <Route path=":edit" element={<EditUserPage />} />
                </Route>
              </Route>
            </Routes>
            <Footer />
          </AppLoader>
          <ToastContainer />
        </GoogleReCaptchaProvider>
      </HashRouter>
    </div>
  );
}

export default App;
