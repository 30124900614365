import { createSlice } from "@reduxjs/toolkit";
import fileService from "../service/file.service";
import history from "../utils/history";
import localStorageService from "../service/http.localStorage";

const fileUploadSlice = createSlice({
  name: "fileUpload",
  initialState: {
    files: [],
    isLoading: false,
    fileLoaded: false,
    error: null,
  },
  reducers: {
    fileUploadRequested: (state) => {
      state.isLoading = true;
    },
    fileUploadReceived: (state, action) => {
      if (action.payload) {
        state.files = action.payload;
      }
      state.isLoading = false;
      state.fileLoaded = true;
    },
    setFile: (state, action) => {
      state.files.push(action.payload);
      state.isLoading = false;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetState: (state) => {
      state.files = [];
      state.isLoading = false;
      state.error = null;
      state.fileLoaded = false;
    },
  },
});

const { reducer: fileUploadReducer, actions } = fileUploadSlice;

export const {
  fileUploadRequested,
  fileUploadReceived,
  setFile,
  setLoading,
  setError,
  resetState,
} = actions;

export const loadFilesUploadList = (taskId) => async (dispatch) => {
  dispatch(fileUploadRequested());
  const groupId = localStorageService.getGroupId();

  try {
    const { content } = await fileService.get(groupId, taskId);
    dispatch(fileUploadReceived(content));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const uploadFile = (file, taskId) => async (dispatch) => {
  dispatch(fileUploadRequested());
  try {
    const formData = new FormData();
    formData.append("file", file);

    const { content } = await fileService.upload(formData, taskId);
    if (content) {
      dispatch(setFile(content));
    }
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const logOutFile = () => (dispatch) => {
  dispatch(resetState());
  history.push("/");
};

export const getFileUpload = (playId, taskId) => (state) => {
  return state.fileUpload.files.length > 0
    ? state.fileUpload.files.filter(
        (elem) => elem.playId === playId && elem.taskId === taskId
      )
    : state.fileUpload.files;
};

export const getFileUploadStatus = () => (state) => state.fileUpload.fileLoaded;

export default fileUploadReducer;
