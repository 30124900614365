import React, { useEffect, useState } from "react";
import { validator } from "../utils/validator";
import TextField from "../components/common/form/TextField";
import { useDispatch, useSelector } from "react-redux";
import { findUserByEmail, getCurrentUserData } from "../store/users";
import { createGroup, getGroupById, getGroupByName } from "../store/groups";
import { getInvitationById } from "../store/invitations";
import SelectField from "../components/common/form/SelectField";
import { getTowns } from "../store/towns";

const CreateTeamPage = () => {
  const dispatch = useDispatch();
  const towns = useSelector(getTowns());

  const currentUser = useSelector(getCurrentUserData());
  const group = useSelector(getGroupById(currentUser && currentUser.teamId));

  const [data, setData] = useState({
    teamname: "",
    memberOne: currentUser && currentUser._id,
    memberTwo: "",
    memberThree: "",
    town: "",
  });

  const [errors, setErrors] = useState({});
  const [block, setBlock] = useState(false);

  const team = useSelector(getGroupByName(data.teamname));
  const memberTwo = useSelector(findUserByEmail(data.memberTwo));
  const memberThree = useSelector(findUserByEmail(data.memberThree));
  const invitationTwo = useSelector(getInvitationById(memberTwo?._id));
  const invitationThree = useSelector(getInvitationById(memberThree?._id));

  const setSelectTown = (arr) => {
    return arr.map((elem) => ({ value: elem._id, label: elem.name }));
  };

  const validatorConfig = {
    teamname: {
      isRequired: {
        message: "Название обязательно для заполнения",
      },
      isLetter: {
        message: "TeamName введен некорректно",
      },
      min: {
        message: "3-30 символов",
        value: 3,
      },
      checkName: {
        message: "Название группы существует",
        value: team,
      },
      max: {
        message: "Имя слишком длинное",
        value: 32,
      },
    },
    memberTwo: {
      isRequired: {
        message: "Введите email напарника",
      },
      isEmail: {
        message: "Введите email корректно",
      },
      checkMember: {
        message: "User не существует",
        value: memberTwo,
      },
      checkGroup: {
        message: "User состоит в группе",
        value: memberTwo?.teamId,
      },
      checkInvitation: {
        message: "User приглашен",
        value: invitationTwo,
      },
      isCurrentUser: {
        message: "Приглашаете себя",
        value: currentUser?.email.toLowerCase(),
      },
      isRepeatUser: {
        message: "Приглашение повторяется",
        value: memberThree?.email.toLowerCase(),
      },
      max: {
        message: "Email не более 32 символов",
        value: 32,
      },
    },
    memberThree: {
      isRequired: {
        message: "Введите email напарника",
      },
      isEmail: {
        message: "Введите email корректно",
      },
      checkMember: {
        message: "User не существует",
        value: memberThree,
      },
      checkGroup: {
        message: "User состоит в группе",
        value: memberThree?.teamId,
      },
      checkInvitation: {
        message: "User приглашен",
        value: invitationThree,
      },
      isCurrentUser: {
        message: "Приглашаете себя",
        value: currentUser?.email.toLowerCase(),
      },
      isRepeatUser: {
        message: "Приглашение повторяется",
        value: memberTwo?.email.toLowerCase(),
      },
      max: {
        message: "Email не более 32 символов",
        value: 32,
      },
    },
    town: {
      isRequireTown: {
        message: "Не выбран город",
      },
    },
  };

  const handleChange = (event) => {
    setData((prevState) => ({
      ...prevState,
      [event.name]: event.value.trim(),
    }));
  };

  const validate = () => {
    const errors = validator(data, validatorConfig);
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    validate();
  }, [data]);

  const isValid = Object.keys(errors).length === 0;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) return;

    const newData = {
      teamname: data.teamname.toLowerCase(),
      status: "build",
      members: [
        { member: data.memberOne, accept: true },
        { member: memberTwo._id, accept: false },
        { member: memberThree._id, accept: false },
      ],
      payment: "unpaid",
      missionId: null,
      playId: null,
      town: data.town,
    };
    dispatch(createGroup({ newData: newData, currentUser: currentUser }));
    setBlock(true);
  };

  return (
    <>
      {group === undefined ? (
        <div className="relative px-10 flex-auto">
          <p className="my-4 text-slate-500 text-lg leading-relaxed">
            Комманда включает 3 человека. Будьте внимательны при заполнений
            полей. Пригласите своих друзей по email.
          </p>
          <form onSubmit={handleSubmit} className="flex flex-col">
            <TextField
              label="Team name:"
              name="teamname"
              placeholder=" Название группы"
              className="w-full bg-slate-100"
              value={data.teamname}
              onChange={handleChange}
              error={errors.teamname}
            />
            <TextField
              label="1 recruit:"
              type="text"
              name="memberTwo"
              value={data.memberTwo}
              onChange={handleChange}
              className="w-full bg-gray-100"
              placeholder=" email приглашенного"
              error={errors.memberTwo}
            />
            <TextField
              label="2 recruit:"
              type="text"
              name="memberThree"
              value={data.memberThree}
              onChange={handleChange}
              className="w-full bg-gray-100"
              placeholder=" email приглашенного"
              error={errors.memberThree}
            />
            <SelectField
              label="Выбери город"
              defaultOption="Choose..."
              options={setSelectTown(towns)}
              name="town"
              onChange={handleChange}
              value={data.town}
              error={errors.town}
            />
            <input
              type="submit"
              disabled={!isValid || block === true}
              value={block === false ? "пригласить" : "отправленно"}
              className="bg-lime-500 hover:text-white hover:bg-lime-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none my-5 mt-5 ease-linear transition-all duration-150"
            />
          </form>
        </div>
      ) : (
        <div className="relative px-10 py-14 flex-auto">
          <p className="flex flex-col font-bold">{`${currentUser.nickname} состоит в команде`}</p>
        </div>
      )}
    </>
  );
};

export default CreateTeamPage;
