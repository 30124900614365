import React from "react";

const Footer = (props) => {
  return (
    <footer className="bg-white rounded-lg shadow dark:bg-gray-800 m-5 mt-40">
      <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2024
          <div className="hover:underline">GO™</div>. All Rights Reserved.
        </span>
        <ul className="flex justify-end items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li className="px-2">
            <a
              href="https://tsg-go.info"
              alt="game-go"
              className="hover:underline"
            >
              Contact
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

Footer.propTypes = {};

export default Footer;
