export class CheckService {
  static checkSecret(word) {
    const check = word.split("").find((elem) => {
      return elem === "-";
    });

    if (check === undefined) {
      return true;
    }
    return false;
  }
}
