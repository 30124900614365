import React from "react";
import { useSelector } from "react-redux";
import { getCurrentUserInvitation } from "../../store/invitations";
import { getUserById } from "../../store/users";

const Invitation = (props) => {
  const invitation = useSelector(getCurrentUserInvitation());
  const invitedUser = useSelector(getUserById(invitation && invitation.from));

  return (
    <>
      {invitation !== null ? (
        <div>
          <div>
            Приглашение в комманду от
            <span className="font-bold">
              {invitedUser ? " " + invitedUser.nickname : ""}
            </span>
          </div>
          <div>
            Для связи{" "}
            <span className="font-bold">
              {invitedUser ? " " + invitedUser.email : ""}
            </span>
          </div>
        </div>
      ) : (
        <div>Нет приглашений</div>
      )}
    </>
  );
};

export default Invitation;
