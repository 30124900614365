import React from "react";
import PropTypes from "prop-types";
import { getGroupById } from "../../store/groups";
import { useSelector } from "react-redux";

const UserGroupName = ({ id }) => {
  const group = useSelector(getGroupById(id));
  return (
    <div className="flex justify-center">
      {group !== undefined ? group.teamname : "не в группе"}
    </div>
  );
};
UserGroupName.propTypes = {
  status: PropTypes.string,
};

export default UserGroupName;
