import localStorageService from "./http.localStorage";
import httpService from "./http.service";

const userEndpoint = "/user/";

const userService = {
  get: async () => {
    const { data } = await httpService.get(userEndpoint);
    return data;
  },
  create: async (payload) => {
    const { data } = await httpService.put(userEndpoint + payload._id, payload);
    return data;
  },
  getCurrentUser: async () => {
    const { data } = await httpService.get(
      userEndpoint + localStorageService.getUserId()
    );
    return data;
  },

  update: async (payload) => {
    const { data } = await httpService.patch(
      userEndpoint + localStorageService.getUserId(),
      payload
    );
    return data;
  },

  createUpdateElem: async (payload) => {
    const { data } = await httpService.put(
      userEndpoint + "autoUpdate/" + payload._id,
      payload
    );
    return data;
  },

  getUpdateElem: async () => {
    const { data } = await httpService.get(
      userEndpoint + "autoUpdate/" + localStorageService.getUserId()
    );
    return data;
  },

  verify: async (payload) => {
    const { data } = await httpService.patch(
      userEndpoint + "verify/" + localStorageService.getUserId(),
      { code: payload }
    );
    return data;
  },
  sendVerify: async () => {
    const { data } = await httpService.get(
      userEndpoint + "verify/" + localStorageService.getUserId()
    );
    return data;
  },
};

export default userService;
