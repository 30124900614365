import httpService from "./http.service";

const submitEndpoint = "submit/";

const captchaService = {
  post: async (token) => {
    const { data } = await httpService.post(submitEndpoint, {
      recaptchaToken: token,
    });
    return data;
  },
};

export default captchaService;
