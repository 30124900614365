import localStorageService from "./http.localStorage";
import httpService from "./http.service";

const groupEndpoint = "group/";

const groupService = {
  create: async (payload) => {
    const { data } = await httpService.post(
      groupEndpoint + localStorageService.getUserId(),
      payload
    );
    return data;
  },
  get: async () => {
    const { data } = await httpService.get(groupEndpoint);
    return data;
  },
  getByEmail: async (email) => {
    const { data } = await httpService.get(groupEndpoint, {
      params: {
        orderBy: "email",
        equalTo: `${email}`,
      },
    });
    return data;
  },
  getByNameAndMembers: async (name, typeName) => {
    const { data } = await httpService.get(
      groupEndpoint + localStorageService.getUserId(),
      {
        params: {
          orderBy: `${typeName}`,
          equalTo: `${name}`,
        },
      }
    );
    return data;
  },
  getOne: async (userId) => {
    const { data } = await httpService.get(groupEndpoint + userId);
    return data;
  },
  // update after adding missionId
  update: async (payload) => {
    const { data } = await httpService.patch(
      groupEndpoint + localStorageService.getUserId(),
      payload
    );
    return data;
  },
  // update before adding missionId
  // updateGroup: async (payload) => {
  //   const { data } = await httpService.patch(
  //     groupEndpoint + localStorageService.getGroupId(),
  //     payload
  //   );
  //   return data;
  // },
  delete: async (payload) => {
    const { data } = await httpService.delete(groupEndpoint + payload.groupId);
    return data;
  },
};
export default groupService;
