import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentGroup } from "../store/groups";
import { getPlay } from "../store/play";
import PlayLoader from "./hoc/playLoader";
import StartLoader from "./hoc/startLoader";
import LocationLoader from "./hoc/locationLoader";
import { getStartByPlayId } from "../store/start";
import { getJobById } from "../store/job";
import JobBlock from "./JobBlock";
import LocationComponent from "../ui/LocationComponent";
import LocationCheck from "../ui/LocationCheck";

const Job = (props) => {
  const dispatch = useDispatch();
  const { missionId } = useParams();
  const group = useSelector(getCurrentGroup());
  const start = useSelector(getStartByPlayId(group && group.playId));
  const play = useSelector(getPlay(group && group.playId));
  const [show, setShow] = useState(false);

  const handleJob = () => {
    setShow(true);
    dispatch(getJobById(play.tasks[start.number], play.job[start.number]));
  };

  const handleShow = () => {
    setShow(false);
  };

  if (group && missionId !== group.missionId) {
    return (
      <div className="flex justify-center font-extrabold text-xl py-20 w-full h-full">
        <div className="bg-black text-white p-10 rounded-lg">
          Твоя команда не в игре
        </div>
      </div>
    );
  } else {
    return (
      <PlayLoader>
        <StartLoader>
          <LocationLoader>
            <div className="w-full flex justify-end my-5 px-5">
              <LocationComponent />
            </div>

            <div className="container w-full h-full text-lg">
              <div className="bg-gray-100">
                <p className="text-red-400 px-3 md:px-20 mt-10">
                  Задание возможно увидеть только в заданной точке
                </p>
              </div>
              {show ? (
                <div className="flex md:mx-5 lx:mx-40 my-20 p-5  bg-yellow-100">
                  <JobBlock
                    handleShow={handleShow}
                    taskId={play.tasks[start.number]}
                    jobId={play.job[start.number]}
                  />
                </div>
              ) : (
                <div className="flex justify-center py-10">
                  <div>
                    <LocationCheck handleJob={handleJob} />
                  </div>
                </div>
              )}
              <div className="mx-5 md:mx-20">
                <ul className="list-disc">
                  <li>
                    Твоя задача выполнить задание снимая все на камеру. Размер
                    файла не превышает 36mb. До трех таких файлов ты сможешь
                    загрузить. Достаточно одного видео доказательства выполнения
                    задания.
                  </li>
                  <li>Внимательно читай задание это обеспечит тебе победу.</li>
                  <li>
                    Выполняя задание внимательно следите за видеообзором
                    выполнения задания. Неправильно снятое видео может повлиять
                    на результаты.
                  </li>
                  <li>Используйте все возможные варианты решения задачи.</li>
                  <li>Наслаждайтесь игрой.</li>
                  <li>
                    Видео оцениваются по многим параметрам учитывайте данный
                    факт.
                  </li>
                  <li>
                    Используйте все возможности вашего организма для принятия
                    решений, выполнения задач и поставленных целей
                  </li>
                </ul>
              </div>
            </div>
          </LocationLoader>
        </StartLoader>
      </PlayLoader>
    );
  }
};

Job.propTypes = {};

export default Job;
