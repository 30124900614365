import { createAction, createSlice } from "@reduxjs/toolkit";
import deleteService from "../service/delete.service";
import localStorageService from "../service/http.localStorage";

const deletesSlice = createSlice({
  name: "deletes",
  initialState: {
    entities: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    deletesRequested: (state) => {
      state.isLoading = true;
    },
    deletesReceived: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
    },
    deletesRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    deletesCreate: (state, action) => {
      if (!Array.isArray(state.entities)) {
        state.entities = [];
      }
      state.entities.push(action.payload);
    },
    deletesUpdateSuccessed: (state, action) => {
      state.entities = state.entities.filter(
        (u) => u._id !== action.payload._id
      );
      state.isLoading = false;
    },
    deleteLoggedOut: (state) => {
      state.entities = null;
      state.isLoading = false;
    },
  },
});

const { reducer: deletesReducer, actions } = deletesSlice;
const {
  deletesRequested,
  deletesReceived,
  deletesRequestFailed,
  deleteLoggedOut,
  deletesCreate,
  deletesUpdateSuccessed,
} = actions;

const addDeleteRequested = createAction("deletes/addDeleteRequested");
const removeDeleteRequested = createAction("deletes/removeDeleteRequested");

export const loadDeletesList = () => async (dispatch) => {
  dispatch(deletesRequested());
  try {
    const { content } = await deleteService.get();
    dispatch(deletesReceived(content));
  } catch (error) {
    dispatch(deletesRequestFailed(error.message));
  }
};

export const createDeletedElem = (payload) => async (dispatch) => {
  dispatch(addDeleteRequested(payload));
  try {
    const { content } = await deleteService.create(payload);
    dispatch(deletesCreate(content));
  } catch (error) {
    dispatch(deletesRequestFailed(error.message));
  }
};

export const removeDeletedElem = (deletedElem) => async (dispatch) => {
  dispatch(removeDeleteRequested());

  if (deletedElem) {
    try {
      const { content } = await deleteService.deleteElem();

      dispatch(deletesUpdateSuccessed(content));
    } catch (error) {
      dispatch(deletesRequestFailed(error.message));
    }
  }
};

export const logOutDelete = () => (dispatch) => {
  dispatch(deleteLoggedOut());
};

export const getCurrentUserDeleteElem = () => (state) => {
  const userId = localStorageService.getUserId();
  if (state.deletes.entities) {
    return state.deletes.entities.find((u) => u.userId === userId);
  }
};

export const getDeletes = () => (state) => state.deletes.entities;
export const getDeletesLoadingStatus = () => (state) => state.deletes.isLoading;
export const getDeleteById = (id) => (state) => {
  if (state.deletes.entities) {
    return state.deletes.entities.find((p) => p._id === id);
  }
};

export default deletesReducer;
