import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getLevelById } from "../store/levels";
import { useNavigate } from "react-router-dom";
import { getSpecialtyById } from "../store/specialties";
import localStorageService from "../service/http.localStorage";

const UserCard = ({ user }) => {
  const navigate = useNavigate();
  const level = useSelector(getLevelById(user.level));
  const specialty = useSelector(getSpecialtyById(user.specialty));

  const handleClick = () => {
    navigate(`/user/${user._id}/edit`);
  };
  return (
    <div className="col-span-1 m-5 ">
      <div className="border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
        {user._id === localStorageService.getUserId() ? (
          <button
            className="mt-2 ml-2 end-0 btn btn-light btn-sm "
            onClick={handleClick}
            disabled={user.teamId === "" ? false : true}
          >
            <svg
              className="h-6 w-6 text-slate-500"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="3" />
              <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
            </svg>
          </button>
        ) : (
          <div className="h-10"></div>
        )}
        <div className="flex flex-col items-center px-5 text-center">
          <img
            src="/img/fone.png"
            className="rounded-full h-150 w-150"
            width="150"
            alt="author"
          />
          <div className="mt-3 text-secondary my-2">
            <h4 className="font-bold">{user.nickname}</h4>
            <p>{specialty?.name}</p>
            <p>{level?.name}</p>
            <p>{user.email}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
UserCard.propTypes = {
  user: PropTypes.object,
};

export default UserCard;
